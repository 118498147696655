import React from 'react';
import { getEventConfig } from '../../eventConfig';

const TradeEvent = ({ event, onClick }) => {
  const { emoji, color } = getEventConfig(event.type);
  return (
    <div 
      className={`calendar-event ${event.type}`}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {emoji} Trade: {event.security_name} ${event.amount.toLocaleString()}
    </div>
  );
};

export default TradeEvent;