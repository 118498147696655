import React, { useState, useEffect } from 'react';
import { formatNumber } from '../../utils/formatters/formatters';
import PositionsSection from './PositionsSection';
import ChartSection from './ChartSection';
import './AccountCard.css';

const AccountCard = ({ accountData, index, accountNameMapping, toggleCardExpansion, isExpanded }) => {
    const [chartData, setChartData] = useState({
        positions: [],
        segments: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const processPositions = () => {
            try {
                setIsLoading(true);
                const positions = accountData.positions;
                
                // Calculate values for pie chart
                const moneyMarketPositions = positions.filter(p => {
                    const price_near_one = 0.99 <= p.price && p.price <= 1.01;
                    return price_near_one; // Simplified check for now
                });

                const moneyMarketValue = moneyMarketPositions
                    .reduce((sum, p) => sum + (p.quantity * p.price), 0);
                
                const equitiesValue = positions
                    .filter(p => !moneyMarketPositions.includes(p))
                    .reduce((sum, p) => sum + (p.quantity * p.price), 0);

                setChartData({
                    positions: positions.map(p => ({
                        ...p,
                        isMoneyMarket: moneyMarketPositions.includes(p)
                    })),
                    segments: [
                        { 
                            name: 'Cash', 
                            value: accountData.balance_info.total_cash,
                            color: '#4CAF50'
                        },
                        { 
                            name: 'Money Market', 
                            value: moneyMarketValue,
                            color: '#2196F3'
                        },
                        { 
                            name: 'Equities', 
                            value: equitiesValue,
                            color: '#9C27B0'
                        }
                    ].filter(segment => segment.value > 0)
                });
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        processPositions();
    }, [accountData]);

    return (
        <div className={`account-card ${isExpanded ? 'expanded' : ''}`}>
            <div className="account-header" onClick={() => toggleCardExpansion(accountData.metadata.account_number)}>
                <div className="header-content">
                    <h3>
                        <i className="fas fa-university"></i>
                        {accountNameMapping[accountData.metadata.account_number]}
                    </h3>
                </div>
                
                {isLoading ? (
                    <div className="loading-spinner">Loading...</div>
                ) : error ? (
                    <div className="error-message">{error}</div>
                ) : (
                    <>
                        <ChartSection data={chartData.segments} />
                        <div className="account-summary">
                            <div className="summary-item">
                                <span>Account Value:</span>
                                <strong>{formatNumber(accountData.balance_info.total_account_value)}</strong>
                            </div>
                            <div className="summary-item">
                                <span>Cash:</span>
                                <strong>{formatNumber(accountData.balance_info.total_cash)}</strong>
                            </div>
                        </div>
                    </>
                )}
            </div>
            
            {isExpanded && (
                <div className="positions-container">
                    <PositionsSection 
                        positions={chartData.positions}
                        type="money-market"
                    />
                    <PositionsSection 
                        positions={chartData.positions}
                        type="other"
                    />
                </div>
            )}
        </div>
    );
};

export default AccountCard;