// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    border: 2px dashed #007bff;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    background-color: #f8f9fa;
  }
  
  .dropzone:hover {
    background-color: #e9ecef;
  }
  
  .dropzone p {
    margin: 0;
    font-size: 16px;
    color: #007bff;
  }`, "",{"version":3,"sources":["webpack://./src/CsvFileDropzone.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,sCAAsC;IACtC,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,SAAS;IACT,eAAe;IACf,cAAc;EAChB","sourcesContent":[".dropzone {\n    border: 2px dashed #007bff;\n    border-radius: 5px;\n    padding: 20px;\n    text-align: center;\n    margin-bottom: 20px;\n    transition: background-color 0.3s ease;\n    background-color: #f8f9fa;\n  }\n  \n  .dropzone:hover {\n    background-color: #e9ecef;\n  }\n  \n  .dropzone p {\n    margin: 0;\n    font-size: 16px;\n    color: #007bff;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
