function secureFilename(filename) {
    if (!filename) {
        return '';
    }
    return filename
        .replace(/[^a-zA-Z0-9_.-]/g, '_') // Replace any character that is not alphanumeric, underscore, dot, or hyphen with an underscore
        .replace(/_+/g, '_') // Replace multiple underscores with a single underscore
        .replace(/^_+|_+$/g, ''); // Remove leading or trailing underscores
}

export default secureFilename;