// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-box {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .google-sign-in-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 24px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .google-sign-in-button:hover {
    background-color: #f8f8f8;
  }
  
  .google-sign-in-button img {
    width: 18px;
    height: 18px;
  }
  
  .error-message {
    color: #d32f2f;
    margin: 1rem 0;
    padding: 8px;
    background: #ffebee;
    border-radius: 4px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,cAAc;IACd,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;EACpB","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f5f5f5;\n  }\n  \n  .login-box {\n    background: white;\n    padding: 2rem;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .google-sign-in-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 10px;\n    padding: 12px 24px;\n    background: white;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.2s;\n  }\n  \n  .google-sign-in-button:hover {\n    background-color: #f8f8f8;\n  }\n  \n  .google-sign-in-button img {\n    width: 18px;\n    height: 18px;\n  }\n  \n  .error-message {\n    color: #d32f2f;\n    margin: 1rem 0;\n    padding: 8px;\n    background: #ffebee;\n    border-radius: 4px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
