// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
    border: 2px dashed var(--primary-color);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
    background-color: var(--light-color);
}

.dropzone:hover {
    background-color: var(--secondary-color);
}

.dropzone p {
    margin: 0;
    font-size: 16px;
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/FileDropzone.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,sCAAsC;IACtC,oCAAoC;AACxC;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,SAAS;IACT,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".dropzone {\n    border: 2px dashed var(--primary-color);\n    border-radius: 5px;\n    padding: 20px;\n    text-align: center;\n    margin-bottom: 20px;\n    transition: background-color 0.3s ease;\n    background-color: var(--light-color);\n}\n\n.dropzone:hover {\n    background-color: var(--secondary-color);\n}\n\n.dropzone p {\n    margin: 0;\n    font-size: 16px;\n    color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
