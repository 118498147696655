import React, { useRef, useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './CapitalCallProcessor.css'; // Import the new CSS file
import { AppContext } from './AppContext';
import FileDropzone from './FileDropzone';
import ErrorBoundary from './ErrorBoundary';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import md5 from 'crypto-js/md5';
import secureFilename from './utils';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { auth } from './firebase';  // Adjust the path based on your file structure
import apiClient from './utils/apiClient';





function CapitalCallProcessor({ state, setState }) {
    const pdfWorkerUrl = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;
    const [loadingTradeFile, setLoadingTradeFile] = useState(false);
    const [collapsedSections, setCollapsedSections] = useState({});
    const [expandedDetails, setExpandedDetails] = useState({});
    const [useMultiLLM, setUseMultiLLM] = useState(true);
    const pdfContainersRef = useRef({});
    const magnifiersRef = useRef({});
    const [fileInfo, setFileInfo] = useState({});
    const [resolvedFields, setResolvedFields] = useState({});
    const [loadedPDFs, setLoadedPDFs] = useState({});
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { SERVER_URL } = useContext(AppContext);

    const handleSLOAFileUpload = async (event) => {
        const files = Array.from(event.target.files);
        if (!files.length) return;
    
        try {
            setState(prevState => ({
                ...prevState,
                sloaUploadStatus: 'uploading'
            }));
    
            const formData = new FormData();
            files.forEach(file => {
                formData.append('files', file);
            });
    
            // Make the API call and get the response
            const response = await apiClient.post('/capital_call/upload_sloa_file', formData);
            console.log('Response:', response);
    
            // Destructure from response directly, not response.data
            const {
                total_records_processed = 0,
                total_files_processed = 0,
                failed_files = []
            } = response || {};
    
            let statusMessage;
            let uploadStatus;
    
            if (failed_files.length > 0) {
                const failedFileNames = failed_files.map(f => f.filename).join(', ');
                statusMessage = `Processed ${total_records_processed} records. Failed files: ${failedFileNames}`;
                uploadStatus = 'warning';
            } else {
                statusMessage = `Successfully processed ${total_records_processed} records from ${total_files_processed} files`;
                uploadStatus = 'success';
            }
    
            setState(prevState => ({
                ...prevState,
                sloaUploadStatus: uploadStatus,
                sloaMessage: statusMessage
            }));
    
            // Clear the message after 5 seconds
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    sloaUploadStatus: null,
                    sloaMessage: null
                }));
            }, 5000);
    
        } catch (error) {
            console.error('SLOA upload failed:', error);
            setState(prevState => ({
                ...prevState,
                sloaUploadStatus: 'error',
                sloaMessage: 'Failed to upload SLOA files. Please check the file format and try again.'
            }));
    
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    sloaUploadStatus: null,
                    sloaMessage: null
                }));
            }, 5000);
        }
    
        // Clear the file input
        event.target.value = '';
    };

    const handleResolveField = (fileIndex, dataType, fieldKey) => {
        setResolvedFields(prev => ({
          ...prev,
          [fileIndex]: {
            ...prev[fileIndex],
            [dataType]: {
              ...(prev[fileIndex]?.[dataType] || {}),
              [fieldKey]: true
            }
          }
        }));
      };

      const handleMouseEnter = useCallback((fileIndex) => {
        const magnifier = magnifiersRef.current[fileIndex];
        if (magnifier) {
            magnifier.style.display = 'block';
        }
    }, []);
    
    const handleMouseLeave = useCallback((fileIndex) => {
        const magnifier = magnifiersRef.current[fileIndex];
        if (magnifier) {
            magnifier.style.display = 'none';
        }
    }, []);
    
    const handleMouseMove = useCallback((e, fileIndex) => {
        const pdfContainer = pdfContainersRef.current[fileIndex];
        const magnifier = magnifiersRef.current[fileIndex];
        const loadedPDF = loadedPDFs[fileIndex];
    
        if (!pdfContainer || !magnifier || !loadedPDF) return;
    
        const rect = pdfContainer.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
    
        if (x < 0 || y < 0 || x > rect.width || y > rect.height) {
            magnifier.style.display = 'none';
            return;
        }
    
        magnifier.style.display = 'block';
    
        // Position the magnifier offset from the cursor
        const offset = 20; // Adjust this value as needed
        let left = e.clientX + offset;
        let top = e.clientY + offset;
    
        // Adjust if too close to right edge of window
        if (left + magnifier.offsetWidth > window.innerWidth) {
            left = e.clientX - magnifier.offsetWidth - offset;
        }
    
        // Adjust if too close to bottom edge of window
        if (top + magnifier.offsetHeight > window.innerHeight) {
            top = e.clientY - magnifier.offsetHeight - offset;
        }
    
        magnifier.style.left = `${left}px`;
        magnifier.style.top = `${top}px`;
    
        const magnificationFactor = 2; // Adjust as needed
    
        // Use the correct PDF for magnification
        const pdf = loadedPDFs[fileIndex];
        pdf.getPage(1).then(page => {
            const viewport = page.getViewport({ scale: 1 });
            const scale = pdfContainer.offsetWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale * magnificationFactor });
    
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = scaledViewport.height;
            canvas.width = scaledViewport.width;
    
            const renderContext = {
                canvasContext: context,
                viewport: scaledViewport
            };
    
            page.render(renderContext).promise.then(() => {
                magnifier.style.backgroundImage = `url(${canvas.toDataURL()})`;
                
                // Calculate the position for the magnifier background
                const bgX = (x / scale) * magnificationFactor;
                const bgY = (y / scale) * magnificationFactor;
                
                // Center the magnification on the cursor
                magnifier.style.backgroundPosition = `-${bgX - magnifier.offsetWidth / 2}px -${bgY - magnifier.offsetHeight / 2}px`;
                magnifier.style.backgroundSize = `${canvas.width}px ${canvas.height}px`;
            });
        });
    }, [loadedPDFs]);

    const toggleExpandDetails = (fileIndex) => {
        setExpandedDetails(prevState => ({
            ...prevState,
            [fileIndex]: !prevState[fileIndex]
        }));
    };
    
    const handleCellChange = (filename, key, newValue, dataType) => {
        console.log(`Updating ${key} for file ${filename} to ${newValue} in ${dataType}`);
    
        setState(prevState => {
            const updatedFileData = { ...prevState.fileData };
            const fileIndex = updatedFileData[dataType].findIndex(data => data.filename === filename);
    
            if (fileIndex === -1) {
                console.error(`File with filename ${filename} not found in ${dataType}`);
                return prevState;
            }
    
            const updatedItem = { ...updatedFileData[dataType][fileIndex] };
            const resultType = {
                capitalCallData: 'schwabResults',
                sloaData: 'sloaResults',
                fundInvestmentData: 'fundResults'
            }[dataType];
    
            updatedItem[resultType] = {
                ...updatedItem[resultType],
                extracted_fields: {
                    ...updatedItem[resultType].extracted_fields,
                    [key]: {
                        value: newValue,
                        confidence: updatedItem[resultType].extracted_fields[key]?.confidence || 1
                    }
                }
            };
    
            // Special handling for 'From Account' field
            if (key === 'From Account') {
                updatedItem['From Account'] = newValue;
            }
    
            updatedFileData[dataType][fileIndex] = updatedItem;
    
            return { ...prevState, fileData: updatedFileData };
        });
    };

    const handleFilesAdded = async (addedFiles) => {
        try {
            console.log('Files to upload:', addedFiles);
            
            const newFiles = addedFiles.map(file => ({
                file: file,
                name: file.name,
                status: 'loading',
                url: URL.createObjectURL(file),
            }));
        
            const formData = new FormData();
            addedFiles.forEach(file => {
                formData.append('file', file);
            });
        
            setState(prevState => ({
                ...prevState,
                files: [...prevState.files, ...newFiles],
                uploadStatus: {
                    ...prevState.uploadStatus,
                    ...Object.fromEntries(newFiles.map(file => [file.name, 'uploading']))
                }
            }));
        
            const response = await apiClient.uploadFiles('/capital_call/upload', formData);
            
            // Update status to 'uploaded' after successful upload
            setState(prevState => ({
                ...prevState,
                uploadStatus: {
                    ...prevState.uploadStatus,
                    ...Object.fromEntries(newFiles.map(file => [file.name, 'uploaded']))
                }
            }));
    
            console.log('Files uploaded successfully:', response);
            
        } catch (error) {
            console.error('Upload failed:', error);
            setState(prevState => ({
                ...prevState,
                uploadStatus: {
                    ...prevState.uploadStatus,
                    ...Object.fromEntries(addedFiles.map(file => [file.name, 'failed']))
                }
            }));
        }
    };

    const handleRunProcessing = async () => {
        try {
            setState(prevState => ({ ...prevState, loading: true }));
            
            const filesToProcess = state.files.filter(fileObj => 
                state.uploadStatus[fileObj.name]?.toLowerCase() === 'uploaded' || 
                state.uploadStatus[fileObj.name]?.toLowerCase() === 'success'
            );
    
            if (filesToProcess.length === 0) {
                throw new Error('Please upload files before processing');
            }
    
            // Debug log the files being processed
            console.log('Files to process:', filesToProcess);
    
            const formData = new FormData();
            filesToProcess.forEach(fileObj => {
                formData.append('file', fileObj.file);
                console.log('Appending file:', fileObj.name);
            });
            
            formData.append('use_multi_llm', String(useMultiLLM));
            console.log('useMultiLLM value:', useMultiLLM);
    
            // Log the full request
            console.log('Sending request to:', '/capital_call/process_files');
            const response = await apiClient.post('/capital_call/process_files', formData);
            
            // Log the full response
            console.log('Full API Response:', response);
    
            if (!response) {
                throw new Error('No response received from server');
            }
            
            if (response.status === 'success' || response.ok) {
                await updateUIWithExtractedData();
    
                const newFileInfo = {};
                state.files.forEach((file, index) => {
                    newFileInfo[index] = {
                        file: file.file,
                        name: file.name,
                        url: URL.createObjectURL(file.file),
                        status: 'processed'
                    };
                });
                setFileInfo(newFileInfo);
            } else {
                throw new Error(response.error || response.message || 'Processing failed');
            }
    
        } catch (error) {
            console.error('Detailed error:', {
                message: error.message,
                stack: error.stack,
                response: error.response
            });
            setState(prevState => ({ 
                ...prevState, 
                csvLoaded: false,
                error: error.message 
            }));
        } finally {
            setState(prevState => ({ ...prevState, loading: false }));
        }
    };

    const parseExtractedFields = (row) => {
        return Object.fromEntries(
            Object.entries(row).map(([key, value]) => {
                // Don't try to parse file_id and filename
                if (key === 'file_id' || key === 'filename') {
                    return [key, value];
                }
    
                if (typeof value === 'string') {
                    // Handle the specific case we've been struggling with
                    if (value.startsWith("{'value':")) {
                        const match = value.match(/{'value': ?"?(.+?)"?,'confidence': ?([\d.]+)}/);
                        if (match) {
                            return [key, {
                                value: match[1].replace(/^['"]|['"]$/g, ''),
                                confidence: parseFloat(match[2])
                            }];
                        }
                    }
    
                    try {
                        // For other cases, attempt to parse as JSON
                        let parsedValue = JSON.parse(value.replace(/'/g, '"'));
                        if (typeof parsedValue.value === 'string') {
                            parsedValue.value = parsedValue.value.replace(/^['"]|['"]$/g, '');
                        }
                        return [key, parsedValue];
                    } catch (e) {
                        // If parsing fails, return the original value
                        return [key, { value, confidence: 1 }];
                    }
                }
                // If it's already an object with value and confidence, return as is
                if (typeof value === 'object' && 'value' in value && 'confidence' in value) {
                    return [key, value];
                }
                // For any other type of value, wrap it in an object with confidence 1
                return [key, { value, confidence: 1 }];
            })
        );
    };

    const updateUIWithExtractedData = async () => {
        try {
            const data = await apiClient.get('/capital_call/get_csv_data', { 
                params: { useMultiLLM } 
            });
            
            console.log('This is the data structure we are receiving from the back end and updating the UI with:', data);
            
            const formattedData = {
                capitalCallData: data.flatMap(fileData => fileData.capitalCallData.map(row => {
                    const fromAccount = ['Crystal Pond Investment LP', 'Crescent Pond Investment LP'].includes(row['From Account']) 
                        ? row['From Account'] 
                        : 'Crystal Pond Investment LP';
                    return {
                        filename: row.filename, 
                        file_id: md5(row.filename).toString(),
                        'From Account': fromAccount,
                        'Amount': row['Amount'],
                        'Process Date': row['Process Date'],
                        'Receiving Bank Account #': row['Receiving Bank Account #'],
                        'Receiving Bank ABA #': row['Receiving Bank ABA #'],
                        'Intermediary Account #': row['Intermediary Account #'],
                        'Intermediary Name': row['Intermediary Name'],
                        'Intermediary Address': row['Intermediary Address'],
                        'Wire Transmission Notes': row['Wire Transmission Notes'],
                        'Distribution Reason': row['Distribution Reason'],
                        schwabResults: {
                            template: 'schwab_template',
                            extracted_fields: {
                                ...parseExtractedFields(row),
                                'From Account': {
                                    value: fromAccount,
                                    confidence: row['From Account'] === fromAccount ? 1 : 0.5
                                }
                            }
                        }
                    };
                })),
                sloaData: data.flatMap(fileData => fileData.sloaData.map(row => ({
                    filename: row.filename, 
                    file_id: md5(row.filename).toString(),
                    'Account Name (From)': row['Account Name (From)'],
                    'Account Name (To)': row['Account Name (To)'],
                    'Account Address (Entity Address)': row['Account Address (Entity Address)'],
                    'Account Number': row['Account Number'],
                    'Routing Number': row['Routing Number'],
                    'Bank Name': row['Bank Name'],
                    'Bank Address': row['Bank Address'],
                    'SLOA on File': row['SLOA on File'] || 'No',
                    sloaResults: {
                        template: 'sloa_template',
                        extracted_fields: parseExtractedFields(row)
                    }
                }))),
                fundInvestmentData: data.flatMap(fileData => fileData.fundInvestmentData?.map(row => ({
                    filename: row.filename,
                    file_id: md5(row.filename).toString(),
                    ...row,
                    fundResults: {
                        template: 'fund_investment_template',
                        extracted_fields: parseExtractedFields(row)
                    }
                }))),
            };
    
            setState(prevState => ({
                ...prevState,
                fileData: formattedData,
                csvLoaded: true,
                error: null  // Clear any previous errors
            }));
    
        } catch (error) {
            console.error('Failed to fetch CSV data:', error);
            setState(prevState => ({ 
                ...prevState, 
                csvLoaded: false,
                error: error.message,
                fileData: null  // Clear any previous data
            }));
        }
    };

    const handleExportAndGenerateTradeFile = async () => {
        setLoadingTradeFile(true);
    
        try {
            console.log('Starting export and trade file generation');
    
            // Prepare data
            const capitalCallData = state.fileData.capitalCallData.map(row => flattenRow(row, 'capitalCall'));
            const sloaData = state.fileData.sloaData.map(row => flattenRow(row, 'sloa'));
            const fundInvestment = state.fileData.fundInvestmentData?.map(row => 
                flattenRow(row, 'fundInvestment')) || [];
    
            console.log("Prepared data:", {
                capitalCallData,
                sloaData,
                fundInvestment
            });
    
            // Step 1: Export CSV with authentication headers
            const csvData = await apiClient.post('/capital_call/export_csv', {
                data: capitalCallData,
                fundInvestmentData: fundInvestment,
                sloaData: sloaData,
                generateTradeFile: true,
                useMultiLLM: useMultiLLM
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`, // Add auth token if you're using one
                    'Content-Type': 'application/json'
                }
            });
    
            if (!csvData || csvData.error) {
                throw new Error(csvData?.error || 'Failed to export CSV data');
            }
    
            // Step 2: Process SLOA data with authentication
            const sloaBlob = await apiClient.post('/capital_call/process_sloa', 
                sloaData, 
                {
                    responseType: 'blob',
                    headers: {
                        'Accept': 'text/csv',
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // Add auth token
                    }
                }
            );
    
            // Step 3: Get trade files with authentication
            const tradeFiles = await apiClient.get('/capital_call/get_generated_trade_files', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}` // Add auth token
                }
            });
    
            if (!tradeFiles || tradeFiles.error) {
                throw new Error(tradeFiles?.error || 'Failed to fetch trade files');
            }
    
            console.log('Fetched trade files:', tradeFiles);
    
            // Step 4: Create and save ZIP
            await createAndSaveZip(
                csvData.csv_content,
                sloaBlob,
                tradeFiles
            );
    
            console.log('Successfully generated and saved all files');
    
        } catch (error) {
            console.error('Failed to generate trade file:', error);
            setState(prevState => ({
                ...prevState,
                error: error.message || 'Failed to generate trade file'
            }));
        } finally {
            setLoadingTradeFile(false);
        }
    };

    const flattenRow = (row, dataType) => {
        const excludeFields = ['filename', 'file_id'];
        const resultType = {
            'capitalCall': 'schwabResults',
            'sloa': 'sloaData',
            'fundInvestment': 'fundResults'
        }[dataType];
    
        // Correctly access the extracted_fields
        const extractedFields = row[resultType]?.extracted_fields || {};
    
        if (dataType === 'capitalCall') {
            const requiredFields = [
                'From Account',
                'Amount',
                'Process Date',
                'Receiving Bank Account #',
                'Receiving Bank ABA #',
                'Intermediary Account #',
                'Intermediary Name',
                'Intermediary Address',
                'Wire Transmission Notes',
                'Distribution Reason'
            ];
    
            return Object.fromEntries(
                requiredFields
                    .filter(key => key in extractedFields || key in row)
                    .map(key => [key, extractValueFromConfidenceDict(extractedFields[key] || row[key])])
            );
        } else if (dataType === 'sloa') {
            const sloaFields = [
                'Account Name (From)',
                'Account Name (To)',
                'Account Address (Entity Address)',
                'Account Number',
                'Routing Number',
                'Bank Name',
                'Bank Address',
                'SLOA on File'
            ];
    
            return Object.fromEntries(
                sloaFields
                    .filter(key => key in extractedFields || key in row)
                    .map(key => [key, extractValueFromConfidenceDict(extractedFields[key] || row[key])])
            );
        } else {
            // For fund investment and any other types
            return Object.fromEntries(
                Object.entries({ ...row, ...extractedFields })
                    .filter(([key]) => !excludeFields.includes(key))
                    .map(([key, value]) => [key, extractValueFromConfidenceDict(value)])
            );
        }
    };

    const extractValueFromConfidenceDict = (data) => {
        if (typeof data === 'object' && data !== null && 'value' in data) {
            return data.value;
        }
        if (typeof data === 'string') {
            try {
                const parsedData = JSON.parse(data.replace(/'/g, '"'));
                if (typeof parsedData === 'object' && parsedData !== null && 'value' in parsedData) {
                    return parsedData.value;
                }
            } catch (error) {
                // If parsing fails, it's not a JSON string, so return the original data
            }
        }
        return data;
    }

    const createAndSaveZip = async (csvContent, sloaBlob, tradeFiles) => {
        const zip = new JSZip();
        const capitalCallFolder = zip.folder('capital_call_output');
    
        const capitalCallBlob = new Blob([csvContent], { type: 'text/csv' });
        capitalCallFolder.file('capital_call_export.csv', capitalCallBlob);
    
        if (sloaBlob) {
            capitalCallFolder.file('sloa_export.csv', sloaBlob);
        }
    
        if (tradeFiles) {
            const tradeFilesFolder = zip.folder('generated_trade_files');
            for (const file of tradeFiles) {
                const fileBlob = new Blob([file.content], { type: 'application/octet-stream' });
                tradeFilesFolder.file(file.name, fileBlob);
            }
        }
    
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, tradeFiles ? 'call_summary_trade_file.zip' : 'capital_call_export.zip');
    };
    
    const handleExportCSV = async () => {
        try {
            console.log('Starting CSV export process');
    
            // Prepare data
            const capitalCallData = state.fileData.capitalCallData.map(row => flattenRow(row, 'capitalCall'));
            const sloaData = state.fileData.sloaData.map(row => flattenRow(row, 'sloa'));
            const fundInvestment = state.fileData.fundInvestmentData?.map(row => 
                flattenRow(row, 'fundInvestment')) || [];
    
            // Step 1: Export CSV
            const csvData = await apiClient.post('/capital_call/export_csv', {
                data: capitalCallData,
                fundInvestmentData: fundInvestment,
                sloaData: sloaData,
                generateTradeFile: false,
                useMultiLLM: useMultiLLM
            });
    
            // Step 2: Process SLOA data
            const sloaBlob = await apiClient.post('/capital_call/process_sloa', sloaData, {
                headers: {
                    'Accept': 'text/csv'
                }
            });
    
            // Step 3: Create and save ZIP (without trade files)
            await createAndSaveZip(
                csvData.csv_content,
                new Blob([sloaBlob], { type: 'text/csv' }),
                null
            );
    
            console.log('Successfully exported CSV and SLOA data');
    
        } catch (error) {
            console.error('Failed to export CSV:', error);
            setState(prevState => ({
                ...prevState,
                error: error.message || 'Failed to export CSV'
            }));
        }
    };

    const toggleCollapse = (index) => {
        setCollapsedSections(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    
    const getConfidence = (value, useMultiLLM) => {
        if (!useMultiLLM) return 1;
        if (typeof value === 'object' && value !== null) {
            return value.confidence !== undefined && value.confidence !== null ? value.confidence : 1;
        }
        return 1;
    };

    const renderCellValue = (value, key, fileIndex, dataType) => {
        if (key === 'From Account') {
            return state.fileData[dataType][fileIndex]['From Account'] || "Crystal Pond Investment LP";
        }
    
        if (typeof value === 'object' && value !== null) {
            if (value.value === '0' || value.value === '0.00' || value.value === 0) {
                return '0.00';
            }
            return value.value || '';
        }
        if (value === '0' || value === '0.00' || value === 0) {
            return '0.00';
        }
        return value || '';
    };

    const getConfidenceStyle = (confidence, fileIndex, dataType, fieldKey) => {
        if (confidence === undefined || confidence === 1) return {};
        if (resolvedFields[fileIndex]?.[dataType]?.[fieldKey]) return {};
        return { backgroundColor: 'rgba(255, 0, 0, 0.2)' }; // Light red background
    };

      useEffect(() => {
        Object.keys(loadedPDFs).forEach(fileIndex => {
          if (loadedPDFs[fileIndex] && pdfContainersRef.current[fileIndex] && magnifiersRef.current[fileIndex]) {
            const fileData = state.fileData.capitalCallData[fileIndex];
            const file = state.files.find(f => f && f.name === fileData.filename);
            
            if (file && !fileInfo[fileIndex]) {
              setFileInfo(prevFileInfo => ({
                ...prevFileInfo,
                [fileIndex]: { url: file.url, name: file.name }
              }));
            }
          }
        });
      }, [loadedPDFs, state.files, state.fileData.capitalCallData, fileInfo]);


      const memoizedFileInfo = useMemo(() => {
        const newFileInfo = {};
        Object.keys(loadedPDFs).forEach(fileIndex => {
          if (loadedPDFs[fileIndex]) {
            const fileData = state.fileData.capitalCallData[fileIndex];
            const file = state.files.find(f => f && f.name === fileData.filename);
            if (file) {
              newFileInfo[fileIndex] = { url: file.url, name: file.name };
            }
          }
        });
        return newFileInfo;
      }, [loadedPDFs, state.files, state.fileData.capitalCallData]);
      
      useEffect(() => {
        setFileInfo(memoizedFileInfo);
      }, [memoizedFileInfo]);


      const renderTableRow = (key, value, fileIndex, dataType, filename) => {
        const confidence = getConfidence(value, useMultiLLM);
        const isResolved = resolvedFields[fileIndex]?.[dataType]?.[key];
        const style = getConfidenceStyle(confidence, fileIndex, dataType, key);
        
        const renderInput = () => {
            if (key === 'From Account') {
                return (
                    <select
                        value={renderCellValue(value, key, fileIndex, dataType)}
                        onChange={(e) => handleCellChange(filename, key, e.target.value, dataType)}
                        title={`Edit ${key}`}
                        className={confidence < 1 && !isResolved ? 'confidence-error' : ''}
                    >
                        <option value="Crystal Pond Investment LP">Crystal Pond Investment LP</option>
                        <option value="Crescent Pond Investment LP">Crescent Pond Investment LP</option>
                    </select>
                );
            } else {
                return (
                    <input
                        type="text"
                        value={renderCellValue(value, key, fileIndex, dataType)}
                        onChange={(e) => handleCellChange(filename, key, e.target.value, dataType)}
                        title={`Edit ${key}`}
                        className={confidence < 1 && !isResolved ? 'confidence-error' : ''}
                    />
                );
            }
        };
    
        return (
            <tr key={key} style={style}>
                <td>{key}</td>
                <td>
                    {renderInput()}
                    {confidence < 1 && !isResolved && (
                        <button onClick={() => handleResolveField(fileIndex, dataType, key)}>
                            Resolve
                        </button>
                    )}
                </td>
            </tr>
        );
    };

    
      return (
        <ErrorBoundary>
            <div className="App">
                <div className="App-header">
                    <h1 className="center-text">Capital Call Document Upload</h1>
                </div>
                <div className="sloa-section">
                <div className="sloa-upload-container">
                    <span className="sloa-label">Update SLOA Records</span>
                    <label className="sloa-upload-button">
                        <input
                            type="file"
                            accept=".xlsx,.xls"
                            multiple
                            onChange={handleSLOAFileUpload}
                            style={{ display: 'none' }}
                        />
                        <span className="upload-icon">↑</span>
                        Upload
                    </label>
                </div>
                {state.sloaMessage && (
                    <div className={`sloa-message ${state.sloaUploadStatus}`}>
                        {state.sloaMessage}
                    </div>
                )}
            </div>
                <div className="llm-toggle-container">
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={useMultiLLM}
                            onChange={(e) => setUseMultiLLM(e.target.checked)}
                        />
                        <span className="slider round"></span>
                    </label>
                    <span className="toggle-label">Use Multi-LLM Processing</span>
                </div>
                <FileDropzone onFilesAdded={handleFilesAdded} />
                <ul className="file-list">
                    {state.files.map(file => (
                        <li key={file.name}>
                            {file.name} - {state.uploadStatus[file.name] || 'Loaded'}
                        </li>
                    ))}
                </ul>
                <div className="button-container">
                    <button onClick={handleRunProcessing} disabled={state.loading} className={state.loading ? 'button-disabled' : ''}>
                        Run Processing
                    </button>
                </div>
                {state.csvLoaded && state.fileData.capitalCallData.map((fileData, fileIndex) => {
                    const file = state.files.find(f => f && secureFilename(f.name) === secureFilename(fileData.filename));
                    const isCollapsed = collapsedSections[fileIndex];
                    const isDetailsExpanded = expandedDetails[fileIndex];
    
                    return (
                        <div key={fileIndex} className="file-container">
                            <div className="collapse-bar" onClick={() => toggleCollapse(fileIndex)}>
                                {isCollapsed ? '▼' : '▲'} {file?.name}
                            </div>
                            <div id={`collapseFile${fileIndex}`} className={`collapse ${isCollapsed ? '' : 'show'}`}>
                                <div className="content-container">
                                    <div className="data-table-container">
                                        <div className="info-container">
                                            <div className="capital-call-info">
                                                <h3>{file?.name || 'Unnamed File'}</h3>
                                                <table className="data-table">
                                                    <thead>
                                                        <tr>
                                                            <th>Field</th>
                                                            <th>Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(fileData.schwabResults?.extracted_fields || {})
                                                            .filter(([key]) => !['filename', 'file_id'].includes(key))
                                                            .map(([key, value]) => renderTableRow(key, value, fileIndex, 'capitalCallData', fileData.filename))}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <button className="expand-details-button" onClick={() => toggleExpandDetails(fileIndex)}>
                                                {isDetailsExpanded ? 'Hide Details' : 'Show More Details'}
                                            </button>
                                            {isDetailsExpanded && (
                                                <>
                                                    {state.fileData.sloaData && state.fileData.sloaData[fileIndex] && (
                                                        <div className="sloa-info">
                                                            <h3>SLOA Information</h3>
                                                            <table className="data-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Field</th>
                                                                        <th>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.entries(state.fileData.sloaData[fileIndex].sloaResults?.extracted_fields || {})
                                                                        .filter(([key]) => !['filename', 'file_id'].includes(key))
                                                                        .map(([key, value]) => renderTableRow(key, value, fileIndex, 'sloaData', fileData.filename))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                    {state.fileData.fundInvestmentData && state.fileData.fundInvestmentData[fileIndex] && (
                                                        <div className="fund-investment-info">
                                                            <h3>Fund Investment Information</h3>
                                                            <table className="data-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Field</th>
                                                                        <th>Value</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.entries(state.fileData.fundInvestmentData[fileIndex].fundResults?.extracted_fields || {})
                                                                        .filter(([key]) => !['filename', 'file_id'].includes(key))
                                                                        .map(([key, value]) => renderTableRow(key, value, fileIndex, 'fundInvestmentData', fileData.filename))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div 
                                        className="pdf-viewer-container" 
                                        ref={el => {
                                            if (el) {
                                                pdfContainersRef.current[fileIndex] = el;
                                            }
                                        }}
                                    >
                                        {file && (
                                            <Worker workerUrl={pdfWorkerUrl}>
                                                <Viewer
                                                    fileUrl={file.url}
                                                    plugins={[pageNavigationPluginInstance]}
                                                    onDocumentLoad={(e) => {
                                                        console.log(`PDF ${fileIndex} loaded. File:`, file);
                                                        setLoadedPDFs(prev => ({ ...prev, [fileIndex]: e.doc }));
                                                    }}
                                                    onDocumentLoadError={(error) => {
                                                        console.error(`Error loading PDF ${fileIndex}:`, error);
                                                    }}
                                                />
                                            </Worker>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                                {state.csvLoaded && (
    <div className="button-container">
        <button 
            onClick={handleExportCSV} 
            disabled={loadingTradeFile || state.loading || !state.csvLoaded}
            style={{ marginRight: '5px' }}
        >
            {state.loading ? 'Exporting...' : 'Export CSV'}
        </button>
        <button 
            onClick={handleExportAndGenerateTradeFile} 
            disabled={loadingTradeFile || state.loading || !state.csvLoaded}
        >
            {loadingTradeFile ? 'Generating...' : 'Export and Generate Trade File'}
        </button>
    </div>
)}
{(loadingTradeFile || state.loading) && <div className="loading-icon">⏳</div>}
            </div>
            
        </ErrorBoundary>
    );
}

export default CapitalCallProcessor;
