import React from 'react';

const TradeDetails = ({ event }) => {
  // Helper function to safely format numbers
  const formatNumber = (value) => {
    return value != null ? value.toLocaleString() : 'N/A';
  };

  // Helper function to safely format currency
  const formatCurrency = (value) => {
    return value != null ? `$${value.toLocaleString()}` : 'N/A';
  };

  return (
    <>
      <p><strong>Security Name:</strong> {event.security_name || 'N/A'}</p>
      <p><strong>Ticker:</strong> {event.ticker || 'N/A'}</p>
      <p><strong>Trade Date:</strong> {event.date ? new Date(event.date).toLocaleString() : 'N/A'}</p>
      <p><strong>Quantity:</strong> {formatNumber(event.quantity)}</p>
      <p><strong>Price per Share:</strong> {formatCurrency(event.price_per_share)}</p>
      <p><strong>Total Value:</strong> {formatCurrency(event.total_value)}</p>
      <p><strong>Resulting Position Size:</strong> {formatNumber(event.resulting_position_size)}</p>
    </>
  );
};

export default TradeDetails;