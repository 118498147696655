import React, { useState } from 'react';
import { Card, Tag, Button, Select, Popconfirm, notification } from 'antd';
import { DownOutlined, UpOutlined, DeleteOutlined } from '@ant-design/icons';
import apiClient from '../utils/apiClient';
import './CapitalCallCard.css';


const { Option } = Select;

const STAGES = [
  'Pending Upload',
  'Wire Sent',
  'Completed',
  'Canceled'
];

const getStatusColor = (stage) => {
    switch (stage.toLowerCase()) {
      case 'pending upload': return 'blue';
      case 'completed': return 'green';
      case 'wire sent': return 'orange'
      case 'canceled': return 'red';
      default: return 'default';
    }
  };
  
const getSloaColor = (isVerified) => {
    return isVerified ? 'green' : 'red';
  };

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
};

const CapitalCallCard = ({ call, onUpdate = () => {} }) => {
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  
  const handleStageChange = async (newStage) => {
      try {
          const response = await apiClient.put('/cashflow_calendar/update_capital_call_stage', {
              id: call.id,
              stage: newStage
          });
          
          if (response.status === 'success') {
              notification.success({
                  message: 'Stage Updated',
                  description: 'Capital call stage has been updated successfully.'
              });
              onUpdate(); // Refresh the parent component
          }
      } catch (error) {
          notification.error({
              message: 'Update Failed',
              description: 'Failed to update capital call stage.'
          });
      }
      setIsEditing(false);
  };

  const handleDelete = async () => {
    try {
        console.log('🗑️ Attempting to delete capital call:', call.id);
        const response = await apiClient.delete(`/cashflow_calendar/delete_capital_call/${call.id}`);
        
        if (response.status === 'success') {
            notification.success({
                message: 'Deleted',
                description: 'Capital call has been deleted successfully.'
            });
            // Pass both the ID and the call date to help identify the calendar event
            onUpdate({ 
                id: call.id, 
                date: call.date,
                type: 'capital-call'
            });
        } else {
            console.error('❌ Delete failed:', response);
            notification.error({
                message: 'Delete Failed',
                description: response.message || 'Failed to delete capital call.'
            });
        }
    } catch (error) {
        console.error('❌ Delete error:', error);
        notification.error({
            message: 'Delete Failed',
            description: error.message || 'Failed to delete capital call.'
        });
    }
};

  return (
      <div className="capital-call-row">
          <div>{formatDate(call.date)}</div>
          <div>{formatCurrency(call.amount)}</div>
          <div>
              <Tag color={getSloaColor(call.is_verified)}>
                  {call.is_verified ? 'Yes' : 'No'}
              </Tag>
          </div>
          <div>{call.fund_name}</div>
          <div>{call.account_name_from}</div>
          <div>
              {isEditing ? (
                  <Select
                      defaultValue={call.stage}
                      style={{ width: 120 }}
                      onChange={handleStageChange}
                      onBlur={() => setIsEditing(false)}
                      autoFocus
                  >
                      {STAGES.map(stage => (
                          <Option key={stage} value={stage}>{stage}</Option>
                      ))}
                  </Select>
              ) : (
                  <Tag 
                      color={getStatusColor(call.stage)}
                      onClick={() => setIsEditing(true)}
                      style={{ cursor: 'pointer' }}
                  >
                      {call.stage || 'N/A'}
                  </Tag>
              )}
          </div>
          <div className="action-buttons">
              <Popconfirm
                  title="Are you sure you want to delete this capital call?"
                  onConfirm={handleDelete}
                  okText="Yes"
                  cancelText="No"
              >
                  <Button 
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                  />
              </Popconfirm>
              <Button 
                  type="link" 
                  onClick={() => setExpanded(!expanded)}
                  icon={expanded ? <UpOutlined /> : <DownOutlined />}
              >
                  {expanded ? 'Less' : 'More'}
              </Button>
          </div>
          {expanded && (
              <div className="capital-call-details">
                  <p>Fed Ref: {call.fed_ref || 'N/A'}</p>
                  {/* Add more details here if needed */}
              </div>
          )}
      </div>
  );
};

export default CapitalCallCard;