import React, { useState, useEffect, useMemo, useContext } from 'react';
import { AppContext } from './AppContext';
import './CashflowCalendar.css';
import TransactionDetailsModal from './components/TransactionDetailsModal';
import { getEventConfig } from './eventConfig';  
import apiClient from './utils/apiClient';
import CapitalCallList from './components/CapitalCallList';
import { Select, Popconfirm } from 'antd';
const { Option } = Select;





const CashflowCalendar = () => {
    const [view, setView] = useState('week');
    const [date, setDate] = useState(new Date());
    const [cashflowData, setCashflowData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [alerts, setAlerts] = useState({ reconciled: [], canceled: [] });
    const { SERVER_URL } = useContext(AppContext);
    const [activityData, setActivityData] = useState([]);

    const accountEmojis = {
        'CRYSTAL POND INVESTMENT LP': '💎',
        'CRESCENT POND INVESTMENT LP': '🌙',
        '87656212': '💎',
        '78752591': '🌙'
    };

    const CalendarKey = () => (
        <div className="calendar-key">
            <div className="key-item">
                <span className="key-emoji">💎</span> Crystal Pond Investment LP
            </div>
            <div className="key-item">
                <span className="key-emoji">🌙</span> Crescent Pond Investment LP
            </div>
            <div className="key-item">
                <span className="key-color capital_call"></span> Capital Call
            </div>
            <div className="key-item">
                <span className="key-color trade"></span> Trade
            </div>
        </div>
    );


    const handleEventClick = async (eventId, eventType) => {
        try {
            const response = await apiClient.get('/cashflow_calendar/get_event_details', {
                params: {
                    event_id: eventId,
                    event_type: eventType
                }
            });
            
            // The response is already parsed JSON from apiClient.get
            if (response.status === 'success' && response.event_details) {
                setSelectedTransaction(response.event_details);
            } else {
                console.error('Invalid data received:', response);
                setSelectedTransaction(null);
            }
        } catch (error) {
            console.error('Error fetching event details:', error);
            setSelectedTransaction(null);
        }
    };

    const today = useMemo(() => {
        const t = new Date();
        return new Date(t.getFullYear(), t.getMonth(), t.getDate());
    }, []);

    useEffect(() => {
        fetchCashflowData();
    }, [date, view]);

    const handleCapitalCallUpdate = (deletedCall) => {
        console.log('🗑️ Handling capital call deletion:', deletedCall);
        
        // Update cashflowData to remove the deleted call
        if (deletedCall) {
            setCashflowData(prevData => prevData.filter(event => 
                !(event.type === 'capital-call' && event.id === deletedCall.id)
            ));
            
            // Optionally refresh all data
            fetchCashflowData();
            fetchActivityData();
            fetchAlerts();
        }
    };

    const fetchActivityData = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await apiClient.get('/cashflow_calendar/get_all_activities');
            
            // Check if response exists and has the correct structure
            if (response && response.status === 'success' && Array.isArray(response.activity_data)) {
                setActivityData(response.activity_data);
                console.log('✅ Fetched activities:', response.activity_data.length);
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('❌ Error fetching activity data:', error);
            setError(error.message);
            setActivityData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAlerts = async () => {
        try {
            console.log('🔔 Fetching alerts...');
            const response = await apiClient.get('/cashflow_calendar/generate_report');
            
            console.log('📊 Alert response:', response);  // Debug log
            
            if (response && response.status === 'success' && response.report) {
                setAlerts({
                    reconciled: response.report.reconciled_transactions || [],
                    canceled: response.report.canceled_transactions || []
                });
                console.log('✅ Alerts updated successfully');
            } else {
                console.error('❌ Invalid alert response format:', response);
                setAlerts({ reconciled: [], canceled: [] });
            }
        } catch (error) {
            console.error('❌ Error fetching report:', error);
            setAlerts({ reconciled: [], canceled: [] });
        }
    };

    useEffect(() => {
        fetchCashflowData();
        fetchActivityData()
        fetchAlerts();
    }, [date, view]);


    
    const fetchCashflowData = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const startDate = new Date();
            startDate.setMonth(startDate.getMonth() - 6);
            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 6);
    
            console.log('📅 Fetching cashflow data...');
            
            const response = await apiClient.get('/cashflow_calendar/get_cashflow_data', {
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0]
                }
            });
    
            console.log('📊 Cashflow data received:', response);
    
            if (response.status === 'success' && Array.isArray(response.cashflow_data)) {
                console.log('✅ Setting cashflow data:', response.cashflow_data.length, 'items');
                setCashflowData(response.cashflow_data);
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('❌ Error fetching cashflow data:', error);
            setError(error.message);
            setCashflowData([]);
        } finally {
            setIsLoading(false);
        }
    };

    const isSameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
               d1.getMonth() === d2.getMonth() &&
               d1.getDate() === d2.getDate();
    };

    const getWeekDates = (date) => {
        const start = new Date(date);
        start.setDate(start.getDate() - start.getDay());
        const dates = [];
        for (let i = 0; i < 7; i++) {
            const day = new Date(start);
            day.setDate(start.getDate() + i);
            dates.push(day);
        }
        return dates;
    };

    const getMonthDates = (date) => {
        const start = new Date(date.getFullYear(), date.getMonth(), 1);
        const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const dates = [];
        for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
            dates.push(new Date(d));
        }
        return dates;
    };

    const getDayClass = (day) => {
        const classes = [];

        if (isSameDay(day, today)) {
            classes.push('current-day');
        } else if (day < today) {
            classes.push('past-day');
        }

        if (day.getMonth() !== date.getMonth()) {
            classes.push('other-month');
        }

        if (view === 'week') {
            classes.push('week-view');
        }

        return classes.join(' ');
    };

    const handleViewChange = (newView) => {
        setView(newView);
        setDate(new Date()); // Reset to current date
    };

    const navigatePrevious = () => {
        const newDate = new Date(date);
        if (view === 'week') {
            newDate.setDate(newDate.getDate() - 7);
        } else {
            newDate.setMonth(newDate.getMonth() - 1);
        }
        setDate(newDate);
    };

    const navigateNext = () => {
        const newDate = new Date(date);
        if (view === 'week') {
            newDate.setDate(newDate.getDate() + 7);
        } else {
            newDate.setMonth(newDate.getMonth() + 1);
        }
        setDate(newDate);
    };

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const getDateRange = () => {
        if (view === 'week') {
            const weekDates = getWeekDates(date);
            return `${formatDate(weekDates[0])} - ${formatDate(weekDates[6])}`;
        } else {
            return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long' });
        }
    };

    const renderCalendar = () => {
        const dates = view === 'week' ? getWeekDates(date) : getMonthDates(date);
        const dayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    
        return (
            <div className="calendar">
                <div className="calendar-header">
                    {dayNames.map(day => <div key={day} className="calendar-day-name">{day}</div>)}
                </div>
                <div className="calendar-body">
                    {dates.map((day, index) => (
                        <div key={index} className={`calendar-day ${getDayClass(day)}`}>
                            <div className="calendar-day-number">{day.getDate()}</div>
                            <div className="calendar-events-container">
                                {renderEvents(day)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const normalizeString = (str) => {
        return str ? str.toLowerCase().replace(/\s+/g, '') : '';
    };

    const getActivityDisplayText = (source) => {
        const normalizedSource = normalizeString(source);
        switch (normalizedSource) {
            case 'myq':
                return "Pending Wire";
            case 'sacdocusign':
                return "DocuSign Verification";
            default:
                return source || "Unknown Source";
        }
    };

    const renderEvents = (day) => {
        if (isLoading) return <div>Loading...</div>;
        if (error) return <div>Error: {error}</div>;
        if (!cashflowData || cashflowData.length === 0) return <div>No events</div>;
    
        const eventsForDay = cashflowData.filter(event => {
          const eventDate = new Date(event.date);
          return eventDate.toDateString() === day.toDateString();
        });
    
        return (
          <>
            {eventsForDay.map((event, index) => {
              const { component: EventComponent } = getEventConfig(event.type);
              return (
                <EventComponent
                  key={index}
                  event={event}
                  onClick={() => handleEventClick(event.id, event.type)}
                />
              );
            })}
          </>
        );
      };
    

    return (
        <div className="cashflow-calendar">
            <h2>Cashflow Calendar</h2>
            <div className="view-buttons">
                <button onClick={() => handleViewChange('week')} className={view === 'week' ? 'active' : ''}>Week View</button>
                <button onClick={() => handleViewChange('month')} className={view === 'month' ? 'active' : ''}>Month View</button>
            </div>
            <div className="navigation">
                <button onClick={navigatePrevious}>&lt; Previous</button>
                <span className="date-range">{getDateRange()}</span>
                <button onClick={navigateNext}>Next &gt;</button>
            </div>
            <CalendarKey />
            {isLoading ? (
                <div>Loading calendar data...</div>
            ) : error ? (
                <div>Error loading calendar: {error}</div>
            ) : (
                renderCalendar()
            )}
            {selectedTransaction && (
            <TransactionDetailsModal 
                event={selectedTransaction} 
                onClose={() => setSelectedTransaction(null)}
            />
            )}
            <div className="alerts">
                <h3>Alerts</h3>
                <div className="alert-section">
                    <h4>Reconciled Transactions</h4>
                    {alerts.reconciled.length > 0 ? (
                        alerts.reconciled.map((alert, index) => (
                            <div key={index} className="alert">
                                {alert.date}: {alert.description} - ${alert.amount.toLocaleString()}
                            </div>
                        ))
                    ) : (
                        <div>No reconciled transactions</div>
                    )}
                </div>
                <div className="alert-section">
                    <h4>Canceled Transactions</h4>
                    {alerts.canceled.length > 0 ? (
                        alerts.canceled.map((alert, index) => (
                            <div key={index} className="alert">
                                {alert.date}: {alert.description} - ${alert.amount.toLocaleString()}
                            </div>
                        ))
                    ) : (
                        <div>No canceled transactions</div>
                    )}
                </div>
            </div>
            <div className="capital-call-list-section">
                <h2>Capital Calls</h2>
                <CapitalCallList onUpdate={handleCapitalCallUpdate} />
            </div>
        </div>
    );
};

export default CashflowCalendar;