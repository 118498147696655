import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyAJTWun2hHkMsUxwi0DQmi3obdPEgJys2Y",
    authDomain: "investmentopstool.firebaseapp.com",
    projectId: "investmentopstool",
    storageBucket: "investmentopstool.appspot.com",
    messagingSenderId: "569156206037",
    appId: "1:569156206037:web:950774717787c2ced0915c"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

// Set persistence to LOCAL (user stays logged in after page refresh)
setPersistence(auth, browserLocalPersistence);

export { auth };