// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease; /* Apply fade-in animation */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 500px;
    width: 100%;
    animation: fadeIn 0.3s ease; /* Apply fade-in animation */
}

.modal-content h2 {
    margin-top: 0;
}

.modal-content button {
    margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/TransactionDetailsModal.css"],"names":[],"mappings":"AAAA;IACI;QACI,UAAU;IACd;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B,EAAE,4BAA4B;AAC7D;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,2BAA2B,EAAE,4BAA4B;AAC7D;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@keyframes fadeIn {\n    from {\n        opacity: 0;\n    }\n    to {\n        opacity: 1;\n    }\n}\n\n.modal-backdrop {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    animation: fadeIn 0.3s ease; /* Apply fade-in animation */\n}\n\n.modal-content {\n    background-color: white;\n    padding: 20px;\n    border-radius: 5px;\n    max-width: 500px;\n    width: 100%;\n    animation: fadeIn 0.3s ease; /* Apply fade-in animation */\n}\n\n.modal-content h2 {\n    margin-top: 0;\n}\n\n.modal-content button {\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
