/**
 * Format a number as currency
 * @param {number} value - The number to format
 * @param {string} [currency='USD'] - The currency code
 * @returns {string} Formatted currency string
 */
export const formatNumber = (value, currency = 'USD') => {
    if (value === undefined || value === null) return 'N/A';
    
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
};

/**
 * Format share quantities with appropriate decimal places
 * @param {number} shares - The number of shares
 * @param {number} [decimals=3] - Number of decimal places
 * @returns {string} Formatted share quantity
 */
export const formatShares = (shares, decimals = 3) => {
    if (shares === undefined || shares === null) return 'N/A';
    
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(shares);
};

/**
 * Format a percentage value
 * @param {number} value - The percentage value
 * @param {number} [decimals=2] - Number of decimal places
 * @returns {string} Formatted percentage
 */
export const formatPercent = (value, decimals = 2) => {
    if (value === undefined || value === null) return 'N/A';
    
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }).format(value / 100);
};

/**
 * Format a date string
 * @param {string|Date} date - The date to format
 * @returns {string} Formatted date string
 */
export const formatDate = (date) => {
    if (!date) return 'N/A';
    
    const options = { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
    };
    
    return new Date(date).toLocaleDateString('en-US', options);
};