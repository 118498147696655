import React from 'react';

export const AppContext = React.createContext(null);

export const AppProvider = ({ children }) => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5001';
  const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'staging';

  return (
    <AppContext.Provider value={{ SERVER_URL, ENVIRONMENT }}>
      {children}
    </AppContext.Provider>
  );
};