import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';

const AuthContext = createContext();

const getServerUrl = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  
  if (!SERVER_URL) {
    console.warn('SERVER_URL not defined, using default production URL');
    return 'https://smartsfo.com/api';
  }

  // Ensure URL ends with /api
  if (!SERVER_URL.endsWith('/api')) {
    console.log(`Adding /api to SERVER_URL: ${SERVER_URL}`);
    return `${SERVER_URL}/api`;
  }

  console.log('Using SERVER_URL:', SERVER_URL);
  return SERVER_URL;
};

const API_URL = getServerUrl();

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessionInitialized, setSessionInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          // Verify user's email domain
          const email = user.email;
          if (!email.endsWith('@callapond.com')) {
            console.error('Unauthorized email domain');
            await auth.signOut();
            setUser(null);
            setSessionInitialized(false);
            return;
          }

          const token = await user.getIdToken(true);
          console.log('Verifying session for:', email);
          
          const response = await fetch(`${API_URL}/auth/verify-session`, { 
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            credentials: 'include',
          });
          
          if (response.ok) {
            setSessionInitialized(true);
            setUser(user);
          } else {
            const errorText = await response.text();
            console.error('Session verification failed:', errorText);
            setSessionInitialized(false);
            // Handle specific error cases
            if (response.status === 401) {
              await auth.signOut();
            }
          }
        } catch (error) {
          console.error('Session verification failed:', error.message);
          if (error.message.includes('SSL')) {
            console.error('SSL Certificate Error - Please check domain configuration');
          } else if (error.message.includes('NetworkError')) {
            console.error('Network Error - Please check your connection');
          }
          setSessionInitialized(false);
          await auth.signOut();
        }
      } else {
        setUser(null);
        setSessionInitialized(false);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    user,
    loading,
    sessionInitialized,
    auth
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}