import React, { useState } from 'react';
import { formatNumber, formatShares } from '../../utils/formatters/formatters';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const PositionCard = ({ position }) => {
    const [expanded, setExpanded] = useState(false);
    const marketValue = position.quantity * position.price;

    return (
        <div className="position-card">
            <div className="position-header">
                <div className="symbol-section">
                    <span className="symbol">{position.symbol}</span>
                    <span className="security-name">{position.name}</span>
                </div>
                <div className="position-value">
                    {formatNumber(marketValue)}
                </div>
            </div>
            <div className="position-details">
                <div className="detail-item">
                    <i className="fas fa-chart-bar"></i>
                    <span className="label">Shares:</span>
                    <span className="value">{formatShares(position.quantity)}</span>
                </div>
                <div className="detail-item">
                    <i className="fas fa-dollar-sign"></i>
                    <span className="label">Price:</span>
                    <span className="value">{formatNumber(position.price)}</span>
                </div>
                {position.yieldPercent > 0 && (
                    <div className="detail-item yield-info">
                        <i className="fas fa-percentage"></i>
                        <span className="label">Yield:</span>
                        <span className="value yield">{position.yieldPercent.toFixed(2)}%</span>
                    </div>
                )}
                {position.expenseRatio > 0 && (
                    <div className="detail-item expense-info">
                        <i className="fas fa-tags"></i>
                        <span className="label">Expense Ratio:</span>
                        <span className="value">{position.expenseRatio.toFixed(3)}%</span>
                    </div>
                )}
            </div>
            <Button 
                type="link" 
                onClick={() => setExpanded(!expanded)}
                icon={expanded ? <UpOutlined /> : <DownOutlined />}
            >
                {expanded ? 'Less' : 'More'}
            </Button>
            {expanded && (
                <div className="position-expanded-details">
                    <p><strong>Security Type:</strong> {position.security_type}</p>
                    <p><strong>Dividend Reinvestment:</strong> {position.div_reinv}</p>
                    <p><strong>Capital Gains Reinvestment:</strong> {position.cap_gains_reinv}</p>
                    <p><strong>% of Account:</strong> {position.percent_of_account_assets}%</p>
                </div>
            )}
        </div>
    );
};

export default PositionCard;