import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const XlsFileDropzone = ({ onFilesAdded }) => {
    const onDrop = useCallback(acceptedFiles => {
        onFilesAdded(acceptedFiles);
    }, [onFilesAdded]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
        },
        multiple: true
    });

    return (
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the XLS/XLSX files here ...</p> :
                    <p>Drag 'n' drop XLS/XLSX files here, or click to select files</p>
            }
        </div>
    );
};

export default XlsFileDropzone;