import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { formatNumber } from '../../utils/formatters/formatters';

const COLORS = ['#00C49F', '#0088FE', '#FFBB28'];

const ChartSection = ({ data }) => {
    const totalValue = data.reduce((sum, item) => sum + item.value, 0);

    const CustomTooltip = ({ payload }) => {
        if (payload && payload.length > 0) {
            const item = payload[0].payload;
            return (
                <div className="custom-tooltip">
                    <p className="tooltip-label">{item.name}</p>
                    <p className="tooltip-value">{formatNumber(item.value)}</p>
                    {item.yield !== undefined && (
                        <p className="tooltip-yield">Yield: {item.yield.toFixed(2)}%</p>
                    )}
                    <p className="tooltip-percent">
                        ({((item.value / totalValue) * 100).toFixed(1)}%)
                    </p>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="chart-section">
            <ResponsiveContainer width="100%" height={300}>
                <PieChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                    <Pie
                        data={data}
                        innerRadius={60}
                        outerRadius={80}
                        paddingAngle={5}
                        dataKey="value"
                        animationBegin={0}
                        animationDuration={1000}
                        animationEasing="ease-out"
                        startAngle={90}
                        endAngle={450}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip />} />
                </PieChart>
            </ResponsiveContainer>
            <div className="chart-legend">
                {data.map((entry, index) => (
                    <div key={`legend-${index}`} className="legend-item">
                        <span 
                            className="legend-color" 
                            style={{ backgroundColor: COLORS[index] }}
                        />
                        <span className="legend-text">
                            {entry.name}: {formatNumber(entry.value)}
                            {entry.yield !== undefined && ` (${entry.yield.toFixed(2)}%)`}
                        </span>
                        <span className="legend-percent">
                            ({((entry.value / totalValue) * 100).toFixed(1)}%)
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChartSection;