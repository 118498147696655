import React from 'react';
import PositionCard from './PositionCard';
import MoneyMarketSummary from './MoneyMarketSummary';
import './PositionsSection.css';

const PositionsSection = ({ positions, type }) => {
    const filteredPositions = positions.filter(position => 
        type === 'money-market' ? position.isMoneyMarket : !position.isMoneyMarket
    );

    return (
        <div className={`positions-section ${type}-positions`}>
            <h4 className="positions-header">
                {type === 'money-market' ? (
                    <>
                        <i className="fas fa-piggy-bank"></i> Money Market Positions
                        <MoneyMarketSummary positions={filteredPositions} />
                    </>
                ) : (
                    <>
                        <i className="fas fa-chart-line"></i> Other Positions
                    </>
                )}
            </h4>
            <div className="positions-grid">
                {filteredPositions.map((position, index) => (
                    <PositionCard key={index} position={position} />
                ))}
            </div>
        </div>
    );
};

export default PositionsSection;