import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import CsvFileDropzone from './CsvFileDropzone';
import ErrorBoundary from './ErrorBoundary';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import apiClient from './utils/apiClient';
import './TradeGenProcessor.css';

function TradeGenProcessor({ state, setState }) {
    const [loadingTradeFile, setLoadingTradeFile] = useState(false);
    const { SERVER_URL } = useContext(AppContext);

    const handleCsvFilesAdded = async (addedFiles) => {
        try {
            console.log('📁 Processing CSV files:', addedFiles.map(f => f.name));
            
            const formData = new FormData();
            addedFiles.forEach(file => {
                console.log(`Adding file: ${file.name}`);
                formData.append('files', file);
            });
    
            const newFiles = [...(state.csvFiles || []), ...addedFiles];
            setState(prevState => ({
                ...prevState,
                csvFiles: newFiles,
                uploadStatus: {
                    ...prevState.uploadStatus,
                    ...Object.fromEntries(newFiles.map(file => [file.name, 'Uploading']))
                }
            }));
    
            const result = await apiClient.uploadFiles('/trade_gen/upload_csv', formData);
            console.log('✅ CSV files uploaded:', result);
    
            // Check if result is an array of uploaded files with status
            if (Array.isArray(result) && result.every(file => file.status === 'uploaded')) {
                setState(prevState => ({
                    ...prevState,
                    uploadStatus: {
                        ...prevState.uploadStatus,
                        ...Object.fromEntries(addedFiles.map(file => [file.name, 'Uploaded']))
                    }
                }));
            } else {
                throw new Error('Upload response format invalid');
            }
        } catch (error) {
            console.error('❌ Error uploading CSV files:', error);
            setState(prevState => ({
                ...prevState,
                uploadStatus: {
                    ...prevState.uploadStatus,
                    ...Object.fromEntries(addedFiles.map(file => [file.name, 'Error']))
                }
            }));
        }
    };

    const handleGenerateTradeFile = async () => {
        setLoadingTradeFile(true);
        try {
            console.log('🔄 Generating trade file...');
            
            // Generate trade file
            const response = await apiClient.post('/trade_gen/generate_trade_file');
            if (response.message && response.message.includes('successfully')) {
                console.log('✅ Trade file generated');
            } else {
                throw new Error(response.error || 'Generation failed');
            }
    
            // Fetch generated files
            const tradeFilesResponse = await apiClient.get('/trade_gen/get_generated_trade_files');
            if (Array.isArray(tradeFilesResponse)) {
                console.log('📦 Fetched trade files:', tradeFilesResponse.length);
                
                // Create zip file
                const zip = new JSZip();
                const tradeFilesFolder = zip.folder('generated_trade_files');
    
                tradeFilesResponse.forEach(file => {
                    const fileBlob = new Blob([file.content], { type: 'application/octet-stream' });
                    tradeFilesFolder.file(file.name, fileBlob);
                });
    
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                saveAs(zipBlob, 'generated_trade_files.zip');
                console.log('✅ Trade files downloaded');
            } else {
                throw new Error('Invalid trade files response format');
            }
    
        } catch (error) {
            console.error('❌ Failed to generate trade file:', error);
        } finally {
            setLoadingTradeFile(false);
        }
    };

    return (
        <ErrorBoundary>
            <div className="TradeGenProcessor">
                <h2 className="center-text">Generate Trade File</h2>
                <CsvFileDropzone onFilesAdded={handleCsvFilesAdded} />
                <ul className="file-list">
                    {(state.csvFiles || []).map(file => (
                        <li key={file.name}>
                            {file.name} - {state.uploadStatus?.[file.name] || 'Loaded'}
                        </li>
                    ))}
                </ul>
                <div className="button-container">
                    <button 
                        onClick={handleGenerateTradeFile} 
                        disabled={loadingTradeFile || !(state.csvFiles || []).length}
                    >
                        {loadingTradeFile ? '⏳ Generating...' : 'Generate Trade File'}
                    </button>
                </div>
                {loadingTradeFile && <div className="loading-icon">⏳</div>}
            </div>
        </ErrorBoundary>
    );
}

export default TradeGenProcessor;