const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const apiClient = {
    async fetch(endpoint, options = {}) {
        try {
            console.log('🔍 API Request:', { endpoint, options });
    
            const defaultOptions = {
                credentials: 'include',
                headers: options.body instanceof FormData 
                    ? {
                        'Accept': 'application/json',
                    } 
                    : {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        ...options.headers,
                    },
            };
    
            const fetchOptions = {
                ...defaultOptions,
                ...options,
                headers: {
                    ...defaultOptions.headers,
                    ...(options.headers || {}),
                }
            };
    
            console.log('📡 Fetch options:', fetchOptions);
    
            const response = await fetch(`${SERVER_URL}${endpoint}`, fetchOptions);
            const contentType = response.headers.get('content-type');
            
            let data;
            if (contentType && contentType.includes('application/json')) {
                data = await response.json().catch(() => null);
            } else {
                data = await response.text();
            }
    
            console.log('✅ API Response:', { 
                status: response.status, 
                contentType,
                data 
            });
    
            if (!response.ok) {
                throw new Error(
                    (data && data.message) || 
                    (typeof data === 'string' ? data : 'API request failed')
                );
            }
    
            return data;
        } catch (error) {
            console.error('❌ API Error:', error);
            throw error;
        }
    },

    async put(endpoint, data) {
        try {
            console.log('📝 PUT Request:', { endpoint, data });
            
            const fetchOptions = {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(data)
            };
    
            console.log('📡 PUT options:', fetchOptions);
            
            const response = await fetch(`${SERVER_URL}${endpoint}`, fetchOptions);
            console.log('Response status:', response.status);
    
            const contentType = response.headers.get('content-type');
            const responseText = await response.text();
            console.log('Raw response:', responseText);
    
            let responseData;  // Changed variable name from 'data' to 'responseData'
            if (contentType && contentType.includes('application/json')) {
                responseData = responseText ? JSON.parse(responseText) : {};
            } else {
                responseData = responseText;
            }
    
            console.log('✅ PUT Response:', { 
                status: response.status, 
                contentType,
                data: responseData 
            });
    
            if (!response.ok) {
                throw new Error(
                    (responseData && responseData.message) || 
                    'Failed to update capital call stage'
                );
            }
    
            return responseData;
        } catch (error) {
            console.error('❌ PUT error:', error);
            throw error;
        }
    },

    async delete(endpoint) {
        try {
            console.log('🗑️ DELETE Request:', { endpoint });
            
            return this.fetch(endpoint, { 
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
        } catch (error) {
            console.error('❌ DELETE error:', error);
            throw error;
        }
    },

    async uploadFiles(endpoint, formData) {
        try {
            console.log('📤 Upload Request Debug');
            
            // Debug FormData contents
            for (let [key, value] of formData.entries()) {
                console.log(`FormData Entry - ${key}:`, value instanceof File ? {
                    name: value.name,
                    type: value.type,
                    size: value.size
                } : value);
            }
    
            const response = await fetch(`${SERVER_URL}${endpoint}`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
                // Add this header to help with CORS
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            
            // Log the raw response for debugging
            console.log('Response status:', response.status);
            const responseText = await response.text();
            console.log('Raw response:', responseText);
            
            if (!response.ok) {
                try {
                    const errorData = JSON.parse(responseText);
                    throw new Error(errorData.error || 'Upload failed');
                } catch (parseError) {
                    throw new Error(`Upload failed: ${responseText}`);
                }
            }
            
            // Parse the response only if it's not empty
            const data = responseText ? JSON.parse(responseText) : {};
            return data;
            
        } catch (error) {
            console.error('❌ Upload error:', error);
            throw error;
        }
    },

    async get(endpoint, { params } = {}) {
        try {
            console.log('🔍 GET Request:', { endpoint, params });

            let url = endpoint;
            if (params) {
                const queryString = new URLSearchParams(params).toString();
                url = `${endpoint}?${queryString}`;
            }

            console.log('📡 Final URL:', url);
            return this.fetch(url, { method: 'GET' });
        } catch (error) {
            console.error('❌ GET error:', error);
            throw error;
        }
    },

    async post(endpoint, data, options = {}) {
        try {
            console.log('📤 POST Request:', { 
                endpoint, 
                data: data instanceof FormData 
                    ? Array.from(data.entries()).map(([key, value]) => 
                        value instanceof File ? `${key}: ${value.name}` : `${key}: ${value}`
                      )
                    : data 
            });
            
            const fetchOptions = {
                method: 'POST',
                credentials: 'include'
            };
    
            if (data instanceof FormData) {
                fetchOptions.body = data;
            } else {
                fetchOptions.body = JSON.stringify(data);
                fetchOptions.headers = {
                    'Content-Type': 'application/json',
                    ...options.headers
                };
            }
    
            const response = await fetch(`${SERVER_URL}${endpoint}`, fetchOptions);
            console.log('Response status:', response.status);
    
            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}`);
            }
    
            // Check if response should be treated as blob
            if (options.responseType === 'blob') {
                return response.blob();
            }
    
            // Check content type
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('text/csv')) {
                return response.text();
            }
    
            const responseText = await response.text();
            console.log('Raw response:', responseText);
    
            // Only parse as JSON if we have content and it's not CSV
            return responseText ? JSON.parse(responseText) : {};
    
        } catch (error) {
            console.error('❌ POST error:', error);
            throw error;
        }
    },
};

export default apiClient;