import React, { useState, useEffect } from 'react';
import CapitalCallProcessor from './CapitalCallProcessor';
import TradeGenProcessor from './TradeGenProcessor';
import Dashboard from './Dashboard';
import CashflowCalendar from './CashflowCalendar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import 'antd/dist/reset.css';
import { AppProvider } from './AppContext';
import { auth } from './firebase';
import Login from './components/Login';
import { AuthProvider, useAuth } from './contexts/AuthContext';

function AppContent() {
  const [activeTab, setActiveTab] = useState('capitalCall');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);  // Add this back
  const { user, sessionInitialized } = useAuth();
  const [capitalCallState, setCapitalCallState] = useState({
    files: [],
    uploadStatus: {},
    fileData: {
      capitalCallData: [],
      sloaData: []
    },
    csvLoaded: false,
    loading: false,
  });
  const [tradeGenState, setTradeGenState] = useState({
    schwabFiles: [],
    capitalCallFiles: [],
    uploadStatus: {},
    fileData: [],
    csvLoaded: false,
    loading: false,
  });

  // Check auth state on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !sessionInitialized) {
    return <Login onLoginSuccess={() => {}} />;
  }

  return (
    <AppProvider>
      <div className="App">
        <div className="tabs">
          <button
            className={activeTab === 'capitalCall' ? 'active' : ''}
            onClick={() => setActiveTab('capitalCall')}
          >
            Capital Call
          </button>
          <button
            className={activeTab === 'tradeFile' ? 'active' : ''}
            onClick={() => setActiveTab('tradeFile')}
          >
            Trade File
          </button>
          <button
            className={activeTab === 'dashboard' ? 'active' : ''}
            onClick={() => setActiveTab('dashboard')}
          >
            Dashboard
          </button>
          <button
            className={activeTab === 'cashflowCalendar' ? 'active' : ''}
            onClick={() => setActiveTab('cashflowCalendar')}
          >
            Cashflow Calendar
          </button>
        </div>
        <div className="content-container">
          {activeTab === 'capitalCall' && (
            <CapitalCallProcessor
              state={capitalCallState}
              setState={setCapitalCallState}
            />
          )}
          {activeTab === 'tradeFile' && (
            <TradeGenProcessor
              state={tradeGenState}
              setState={setTradeGenState}
            />
          )}
          {activeTab === 'dashboard' && (
            <Dashboard
              state={tradeGenState}
              setState={setTradeGenState}
            />
          )}
          {activeTab === 'cashflowCalendar' && (
            <CashflowCalendar />
          )}
        </div>
      </div>
    </AppProvider>
  );
}

// Main App component
function App() {
  return (
    <AuthProvider>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </AuthProvider>
  );
}

export default App;