// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.money-market-summary {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
    margin: 1rem 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
  
  .summary-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .summary-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .summary-item span {
    color: #6c757d;
    font-size: 0.9rem;
  }
  
  .summary-item strong {
    color: #212529;
    font-size: 1.1rem;
  }
  
  .estimated-earnings {
    grid-column: 1 / -1;
    padding-top: 0.5rem;
    border-top: 1px solid #dee2e6;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .estimated-earnings strong {
    color: #28a745;
  }`, "",{"version":3,"sources":["webpack://./src/components/dashboard/MoneyMarketSummary.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,cAAc;IACd,sCAAsC;EACxC;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;IAClB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".money-market-summary {\n    background: #f8f9fa;\n    border-radius: 8px;\n    padding: 1rem;\n    margin: 1rem 0;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.05);\n  }\n  \n  .summary-content {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n    gap: 1rem;\n    margin-top: 0.5rem;\n  }\n  \n  .summary-item {\n    display: flex;\n    flex-direction: column;\n    gap: 0.25rem;\n  }\n  \n  .summary-item span {\n    color: #6c757d;\n    font-size: 0.9rem;\n  }\n  \n  .summary-item strong {\n    color: #212529;\n    font-size: 1.1rem;\n  }\n  \n  .estimated-earnings {\n    grid-column: 1 / -1;\n    padding-top: 0.5rem;\n    border-top: 1px solid #dee2e6;\n    margin-top: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .estimated-earnings strong {\n    color: #28a745;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
