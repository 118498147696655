// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.positions-section {
    margin: 1.5rem 0;
    background: #f8f9fa;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .positions-header {
    padding: 1rem 1.5rem;
    margin: 0;
    background: #4a75e6;
    background: linear-gradient(135deg, #4a75e6 0%, #3d62c7 100%);
    color: white;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 12px 12px 0 0;
  }
  
  .positions-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .money-market-positions,
  .other-positions {
    margin-bottom: 2rem;
  }
  
  .positions-header i {
    font-size: 1rem;
    margin-right: 0.5rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/dashboard/PositionsSection.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,sCAAsC;EACxC;;EAEA;IACE,oBAAoB;IACpB,SAAS;IACT,mBAAmB;IACnB,6DAA6D;IAC7D,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,4BAA4B;EAC9B;;EAEA;IACE,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,aAAa;EACf;;EAEA;;IAEE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,oBAAoB;EACtB","sourcesContent":[".positions-section {\n    margin: 1.5rem 0;\n    background: #f8f9fa;\n    border-radius: 12px;\n    box-shadow: 0 2px 8px rgba(0,0,0,0.05);\n  }\n  \n  .positions-header {\n    padding: 1rem 1.5rem;\n    margin: 0;\n    background: #4a75e6;\n    background: linear-gradient(135deg, #4a75e6 0%, #3d62c7 100%);\n    color: white;\n    font-size: 1.1rem;\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    border-radius: 12px 12px 0 0;\n  }\n  \n  .positions-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    gap: 1rem;\n    padding: 1rem;\n  }\n  \n  .money-market-positions,\n  .other-positions {\n    margin-bottom: 2rem;\n  }\n  \n  .positions-header i {\n    font-size: 1rem;\n    margin-right: 0.5rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
