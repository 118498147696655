import React from 'react';
import './TransactionDetailsModal.css';
import { getEventConfig } from '../eventConfig';

const TransactionDetailsModal = ({ event, onClose }) => {
  if (!event) return null;

  const { detailComponent: DetailComponent } = getEventConfig(event.type);

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>{event.type.charAt(0).toUpperCase() + event.type.slice(1)} Details</h2>
        <DetailComponent event={event} />
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default TransactionDetailsModal;