import React from 'react';

const CapitalCallDetails = ({ event }) => (
  <>
    <p><strong>Fund Name:</strong> {event.fund_name}</p>
    <p><strong>Amount:</strong> ${event.amount.toLocaleString()}</p>
    <p><strong>Process Date:</strong> {new Date(event.process_date).toLocaleString()}</p>
    <p><strong>Total Commitment:</strong> ${event.total_commitment ? event.total_commitment.toLocaleString() : 'N/A'}</p>
    <p><strong>Commitment Date:</strong> {event.commitment_date ? new Date(event.commitment_date).toLocaleString() : 'N/A'}</p>
    <p><strong>Unfunded Commitment:</strong> ${event.unfunded_commitment ? event.unfunded_commitment.toLocaleString() : 'N/A'}</p>
    <p><strong>Percentage Funded:</strong> {event.percentage_funded ? `${event.percentage_funded.toFixed(2)}%` : 'N/A'}</p>
    <p><strong>Current Call Percentage:</strong> {event.current_call_percentage ? `${event.current_call_percentage.toFixed(2)}%` : 'N/A'}</p>
    <p><strong>Receiving Bank Account:</strong> {event.receiving_bank_account}</p>
    <p><strong>Receiving Bank ABA:</strong> {event.receiving_bank_aba}</p>
    <p><strong>Intermediary Address:</strong> {event.intermediary_address}</p>
    <p><strong>Wire Transmission Notes:</strong> {event.wire_transmission_notes}</p>
    <p><strong>Distribution Reason:</strong> {event.distribution_reason}</p>
  </>
);

export default CapitalCallDetails;