import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  onAuthStateChanged,
  getAuth 
} from 'firebase/auth';
import './Login.css';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;  // Add this line


function Login({ onLoginSuccess }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verify SERVER_URL is properly set
    if (!SERVER_URL) {
      console.error('SERVER_URL is not configured!');
      setError('Server configuration error');
    }
    
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleExistingSession(user);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleExistingSession = async (user) => {
    try {
      const token = await user.getIdToken(true);
      
      // Increase timeout to 15 seconds
      const controller = new AbortController();
      const timeoutId = setTimeout(() => {
        controller.abort();
        console.error('Request timed out after 15 seconds');
      }, 15000);
      
      console.log('Attempting backend authentication...', {
        serverUrl: SERVER_URL,
        endpoint: `${SERVER_URL}/auth/login`
      });

      const response = await fetch(`${SERVER_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        mode: 'cors',
        signal: controller.signal
      });
      
      clearTimeout(timeoutId);
      
      // Log the full response details
      console.log('Backend response received:', {
        status: response.status,
        statusText: response.statusText,
        headers: Object.fromEntries([...response.headers])
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Backend auth failed:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries([...response.headers]),
          body: errorText,
          serverUrl: SERVER_URL
        });
        throw new Error(`Backend auth failed: ${response.status} ${response.statusText} - ${errorText}`);
      }
  
      const data = await response.json();
      console.log('Login successful:', data);
      onLoginSuccess();
  
    } catch (error) {
      console.error('Login error:', {
        message: error.message,
        type: error.name,
        stack: error.stack,
        serverUrl: SERVER_URL,
        userEmail: user?.email
      });

      if (error.name === 'AbortError') {
        setError('Connection timed out. Please try again.');
      } else {
        setError(error.message || 'Authentication failed. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError('');
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        hd: 'callapond.com',
        prompt: 'select_account'
      });
  
      try {
        const result = await signInWithPopup(auth, provider);
        console.log('Sign-in successful');
        await handleExistingSession(result.user);
      } catch (error) {
        console.error('Detailed sign-in error:', error);
        if (error.code === 'auth/popup-closed-by-user') {
          setError('Sign-in cancelled. Please try again.');
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error('Google sign-in error:', error);
      switch (error.code) {
        case 'auth/popup-blocked':
          setError('Please allow popups for this site');
          break;
        case 'auth/cancelled-popup-request':
          setError('Sign-in was cancelled');
          break;
        case 'auth/popup-closed-by-user':
          setError('Sign-in window was closed');
          break;
        case 'auth/unauthorized-domain':
          setError('This domain is not authorized for sign-in');
          break;
        default:
          setError('Sign-in failed. Please try again.');
          console.error('Detailed error:', error);
      }
    }
  };

  if (loading) {
    return <div className="login-container"><div className="login-box">Loading...</div></div>;
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Welcome</h2>
        <p>Sign in with your company Google account</p>
        
        {error && (
          <div className="error-message">
            <p>{error}</p>
            {error.includes('certificate') && (
              <small>If this persists, please contact IT support.</small>
            )}
          </div>
        )}
        
        <button 
          className="google-sign-in-button" 
          onClick={handleGoogleSignIn}
          disabled={loading}
        >
          <img 
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4="
            alt="Google logo" 
          />
          Sign in with Google
        </button>
      </div>
    </div>
  );
}

export default Login;