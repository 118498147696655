import CapitalCallEvent from './components/events/CapitalCallEvent';
import TradeEvent from './components/events/TradeEvent';
import ActivityEvent from './components/events/ActivityEvent';
import CapitalCallDetails from './components/details/CapitalCallDetails';
import TradeDetails from './components/details/TradeDetails';
import ActivityDetails from './components/details/ActivityDetails';

export const eventConfig = {
  capital_call: {
    component: CapitalCallEvent,
    detailComponent: CapitalCallDetails,
    emoji: '📞',
    color: 'blue',
  },
  trade: {
    component: TradeEvent,
    detailComponent: TradeDetails,
    emoji: '💹',
    color: 'green',
  },
  activity: {
    component: ActivityEvent,
    detailComponent: ActivityDetails,
    emoji: '🔄',
    color: 'orange',
  },
};

export const getEventConfig = (eventType) => {
  return eventConfig[eventType] || {
    component: () => null,
    detailComponent: () => null,
    emoji: '❓',
    color: 'gray',
  };
};