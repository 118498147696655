import React from 'react';
import { useDropzone } from 'react-dropzone';
import './FileDropzone.css';

function FileDropzone({ onFilesAdded }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesAdded,
    accept: {
      'application/pdf': ['.pdf']
    },
    multiple: true
  });

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      <p>Drag & drop PDF files here, or click to select files</p>
    </div>
  );
}

export default FileDropzone;