import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { Row, Col, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CsvFileDropzone from './CsvFileDropzone';
import XlsFileDropzone from './XlsFileDropzone';
import AccountCard from './components/dashboard/AccountCard';
import ChartSection from './components/dashboard/ChartSection';
import apiClient from './utils/apiClient';
import ErrorBoundary from './ErrorBoundary';
import { formatNumber, formatShares } from './utils/formatters/formatters';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Dashboard.css';

const accountNameMapping = {
    '87656212': 'Crystal Pond Investment LP',
    '78752591': 'Crescent Pond Investment LP'
};

const Dashboard = () => {
    const [data, setData] = useState([]);
    const [aggregatedData, setAggregatedData] = useState([]);
    const [xlsFiles, setXlsFiles] = useState([]);
    const [xlsUploadStatus, setXlsUploadStatus] = useState({});
    const [expandedCards, setExpandedCards] = useState({});
    const [viewMode, setViewMode] = useState('detailed');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { SERVER_URL } = useContext(AppContext);
    const [csvFiles, setCsvFiles] = useState([]);
    const [csvUploadStatus, setCsvUploadStatus] = useState({});

    useEffect(() => {
        fetchExistingFiles();
    }, []);

    const fetchExistingFiles = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient.get('/account_dashboard/get_existing_files');
            if (response?.data) {
                setAggregatedData(response.data);
            }
        } catch (error) {
            setError(error.message);
            console.error('Error fetching files:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCsvFilesAdded = async (addedFiles) => {
        const newFiles = [...csvFiles, ...addedFiles];
        setCsvFiles(newFiles);
        setCsvUploadStatus(prevStatus => ({
            ...prevStatus,
            ...Object.fromEntries(addedFiles.map(file => [file.name, 'Loaded']))
        }));
    
        try {
            const formData = new FormData();
            addedFiles.forEach(file => formData.append('file', file));
            const result = await apiClient.uploadFiles('/account_dashboard/upload_csv_files', formData);
    
            if (result.status === 'success') {
                await fetchExistingFiles(); // Refresh data after successful upload
                setCsvUploadStatus(prevStatus => ({
                    ...prevStatus,
                    ...Object.fromEntries(addedFiles.map(file => [file.name, 'Processed']))
                }));
            } else {
                throw new Error(result.error || 'Upload failed');
            }
        } catch (error) {
            console.error('Upload error:', error);
            setCsvUploadStatus(prevStatus => ({
                ...prevStatus,
                ...Object.fromEntries(addedFiles.map(file => [file.name, 'Error']))
            }));
        }
    };

    const renderUploadSection = () => (
        <div className="upload-section">
            <div className="upload-container">
                <div className="upload-box">
                    <Button type="primary" icon={<UploadOutlined />}>
                        Upload Position Files (CSV)
                    </Button>
                    <CsvFileDropzone
                        onFilesAdded={handleCsvFilesAdded}
                        uploadStatus={csvUploadStatus}
                    />
                </div>
                <div className="upload-box">
                    <Button type="primary" icon={<UploadOutlined />}>
                        Upload Activity Files (XLS)
                    </Button>
                    <XlsFileDropzone
                        onFilesAdded={handleXlsFilesAdded}
                        uploadStatus={xlsUploadStatus}
                    />
                </div>
            </div>
        </div>
    );

    const handleXlsFilesAdded = async (addedFiles) => {
        const newFiles = [...xlsFiles, ...addedFiles];
        setXlsFiles(newFiles);
        setXlsUploadStatus(prevStatus => ({
            ...prevStatus,
            ...Object.fromEntries(addedFiles.map(file => [file.name, 'Loaded']))
        }));

        try {
            const formData = new FormData();
            addedFiles.forEach(file => formData.append('file', file));
            const result = await apiClient.uploadFiles('/account_dashboard/upload_activity_files', formData);

            if (result.status === 'success') {
                await fetchExistingFiles(); // Refresh data after successful upload
                setXlsUploadStatus(prevStatus => ({
                    ...prevStatus,
                    ...Object.fromEntries(addedFiles.map(file => [file.name, 'Processed']))
                }));
            } else {
                throw new Error(result.error || 'Upload failed');
            }
        } catch (error) {
            console.error('Upload error:', error);
            setXlsUploadStatus(prevStatus => ({
                ...prevStatus,
                ...Object.fromEntries(addedFiles.map(file => [file.name, 'Error']))
            }));
        }
    };

    const toggleCardExpansion = (accountNumber) => {
        setExpandedCards(prev => ({
            ...prev,
            [accountNumber]: !prev[accountNumber]
        }));
    };


    const renderViewToggle = () => (
        <div className="view-toggle">
            <Button.Group>
                <Button 
                    type={viewMode === 'detailed' ? 'primary' : 'default'}
                    onClick={() => setViewMode('detailed')}
                >
                    Detailed View
                </Button>
                <Button 
                    type={viewMode === 'consolidated' ? 'primary' : 'default'}
                    onClick={() => setViewMode('consolidated')}
                >
                    Consolidated View
                </Button>
            </Button.Group>
        </div>
    );

    const renderAccountCard = (accountData, index) => {
        const isExpanded = expandedCards[accountData.metadata.account_number];
        
        // Calculate values for pie chart
        const moneyMarketPositions = accountData.positions.filter(p => {
            const price_near_one = 0.99 <= p.price && p.price <= 1.01;
            return price_near_one; // Simplified check for now
        });

        const moneyMarketValue = moneyMarketPositions.reduce((sum, p) => sum + (p.quantity * p.price), 0);
        const equitiesValue = accountData.positions
            .filter(p => !moneyMarketPositions.includes(p))
            .reduce((sum, p) => sum + (p.quantity * p.price), 0);
        
        const chartData = [
            { name: 'Cash', value: accountData.balance_info.total_cash },
            { name: 'Money Market', value: moneyMarketValue },
            { name: 'Equities', value: equitiesValue }
        ].filter(segment => segment.value > 0);
    
        return (
            <AccountCard 
                accountData={{
                    ...accountData,
                    positions: accountData.positions.map(p => ({
                        ...p,
                        isMoneyMarket: 0.99 <= p.price && p.price <= 1.01
                    }))
                }}
                index={index}
                accountNameMapping={accountNameMapping}
                toggleCardExpansion={toggleCardExpansion}
                isExpanded={isExpanded}
            />
        );
    };

    const renderConsolidatedView = () => {
        // Aggregate all positions across accounts
        const aggregatedPositions = aggregatedData.reduce((acc, account) => {
            // Calculate money market and equity positions for each account
            const moneyMarketPositions = account.positions.filter(p => 
                0.99 <= p.price && p.price <= 1.01
            );
            const otherPositions = account.positions.filter(p => 
                !(0.99 <= p.price && p.price <= 1.01)
            );

            // Add to running totals
            acc.totalCash += account.balance_info.total_cash || 0;
            acc.moneyMarketValue += moneyMarketPositions.reduce((sum, p) => 
                sum + (p.quantity * p.price), 0);
            acc.equitiesValue += otherPositions.reduce((sum, p) => 
                sum + (p.quantity * p.price), 0);

            return acc;
        }, { totalCash: 0, moneyMarketValue: 0, equitiesValue: 0 });

        const consolidatedChartData = [
            { name: 'Cash', value: aggregatedPositions.totalCash, color: '#4CAF50' },
            { name: 'Money Market', value: aggregatedPositions.moneyMarketValue, color: '#2196F3' },
            { name: 'Equities', value: aggregatedPositions.equitiesValue, color: '#9C27B0' }
        ].filter(segment => segment.value > 0);

        return (
            <div className="consolidated-view">
                <div className="consolidated-summary">
                    <h3>Portfolio Overview</h3>
                    <ChartSection data={consolidatedChartData} />
                    <div className="consolidated-accounts">
                        {aggregatedData.map((accountData, index) => (
                            <div key={index} className="consolidated-account-row">
                                <span>{accountNameMapping[accountData.metadata.account_number]}</span>
                                <span>{formatNumber(accountData.balance_info.total_account_value)}</span>
                            </div>
                        ))}
                        <div className="consolidated-total">
                            <strong>Total Portfolio Value:</strong>
                            <strong>
                                {formatNumber(aggregatedData.reduce((sum, account) => 
                                    sum + account.balance_info.total_account_value, 0
                                ))}
                            </strong>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    if (isLoading) return <div className="loading-spinner">Loading dashboard data...</div>;
    if (error) return <div className="error-message">{error}</div>;

    return (
        <ErrorBoundary>
        <div className="Dashboard container-fluid">
            {renderViewToggle()}
            <div className="action-section">
                <div className="upload-buttons">
                    <Button 
                        type="primary" 
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById('csvDropzone').click()}
                    >
                        Upload Position Files (CSV)
                    </Button>
                    <Button 
                        type="primary" 
                        icon={<UploadOutlined />}
                        onClick={() => document.getElementById('xlsDropzone').click()}
                    >
                        Upload Activity Files (XLS)
                    </Button>
                </div>
                <div className="hidden-dropzones">
                    <div id="csvDropzone">
                        <CsvFileDropzone
                            onFilesAdded={handleCsvFilesAdded}
                            uploadStatus={csvUploadStatus}
                        />
                    </div>
                    <div id="xlsDropzone">
                        <XlsFileDropzone
                            onFilesAdded={handleXlsFilesAdded}
                            uploadStatus={xlsUploadStatus}
                        />
                    </div>
                </div>
            </div>
                {viewMode === 'detailed' ? (
                    <div className="accounts-grid">
                        {aggregatedData.map((accountData, index) => (
                            <div key={accountData.metadata.account_number || index}>
                                {renderAccountCard(accountData, index)}
                            </div>
                        ))}
                    </div>
                ) : (
                    renderConsolidatedView()
                )}
            </div>
        </ErrorBoundary>
    );
};

export default Dashboard;