// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TradeGenProcessor {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .center-text {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .file-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
  }
  
  .file-list li {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .button-container {
    text-align: center;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .loading-icon {
    font-size: 24px;
    color: #4CAF50;
    text-align: center;
    margin-top: 20px;
  }`, "",{"version":3,"sources":["webpack://./src/TradeGenProcessor.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,gBAAgB;EAClB","sourcesContent":[".TradeGenProcessor {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .center-text {\n    text-align: center;\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .file-list {\n    list-style-type: none;\n    padding: 0;\n    margin-bottom: 20px;\n  }\n  \n  .file-list li {\n    background-color: #fff;\n    padding: 10px;\n    margin-bottom: 5px;\n    border-radius: 4px;\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);\n  }\n  \n  .button-container {\n    text-align: center;\n  }\n  \n  button {\n    background-color: #4CAF50;\n    color: white;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s;\n  }\n  \n  button:hover {\n    background-color: #45a049;\n  }\n  \n  button:disabled {\n    background-color: #cccccc;\n    cursor: not-allowed;\n  }\n  \n  .loading-icon {\n    font-size: 24px;\n    color: #4CAF50;\n    text-align: center;\n    margin-top: 20px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
