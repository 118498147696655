import React, { useState, useEffect, useContext } from 'react';
import { Input, Select, DatePicker, Row, Col, Button, notification, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CapitalCallCard from './CapitalCallCard';
import apiClient from '../utils/apiClient';
import './CapitalCallCard.css';

const { RangePicker } = DatePicker;
const { Option } = Select;


const CapitalCallList = ({ onUpdate = () => {} }) => {
  const [capitalCalls, setCapitalCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [accountFilter, setAccountFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');
  const [sloaFilter, setSloaFilter] = useState('');
  const [sortField, setSortField] = useState('date');
  const [sortOrder, setSortOrder] = useState('descend');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [quickFilter, setQuickFilter] = useState('');


  const clearFilters = () => {
    setSearchTerm('');
    setDateRange([]);
    setAccountFilter('');
    setStageFilter('');
    setSloaFilter('');
    setSortField('date');
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCapitalCalls();
  }, [currentPage, pageSize, searchTerm, dateRange, accountFilter, stageFilter, sloaFilter, sortField, sortOrder, quickFilter]);

  const handleUpdate = (deletedCall) => {
    // Pass the deleted call info up to parent for calendar update
    if (onUpdate) {
        onUpdate(deletedCall);
    }
    // Refresh the list
    fetchCapitalCalls();
};


  const fetchCapitalCalls = async () => {
    try {
        console.log('📊 Fetching capital calls...');
        
        // Create params object (similar to original)
        const params = {
            page: currentPage.toString(),
            pageSize: pageSize.toString(),
            searchTerm: searchTerm || '',
            accountFilter: accountFilter || '',
            stageFilter: stageFilter || '',
            sloaFilter: sloaFilter || '',
            sortField: sortField || '',
            sortOrder: sortOrder || '',
            quickFilter: quickFilter || '',
        };

        // Add date range if exists (same as original)
        if (dateRange?.length === 2) {
            params.startDate = dateRange[0].toISOString();
            params.endDate = dateRange[1].toISOString();
        }

        console.log('🔍 Request params:', params);

        // Use apiClient instead of fetch
        const data = await apiClient.get('/cashflow_calendar/get_capital_calls_info', { params });

        // Same response handling as original
        if (data.status === 'success' && Array.isArray(data.capital_calls)) {
            console.log(`✅ Loaded ${data.capital_calls.length} capital calls`);
            setCapitalCalls(data.capital_calls);
            setFilteredCalls(data.capital_calls);
            setTotalItems(data.total);
        } else {
            console.error('❌ Unexpected response format:', data);
            notification.error({
                message: 'Error fetching capital calls',
                description: 'Received unexpected data format from server.'
            });
        }
    } catch (error) {
        console.error('❌ Error fetching capital calls:', error);
        notification.error({
            message: 'Error fetching capital calls',
            description: 'Failed to fetch capital calls data. Please try again later.'
        });
    }
};

  const handleSort = (field) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'ascend' ? 'descend' : 'ascend');
    } else {
      setSortField(field);
      setSortOrder('descend');
    }
    fetchCapitalCalls();
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <div className="capital-call-container">
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Input
            placeholder="Search capital calls"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </Col>
        <Col span={8}>
          <RangePicker 
            onChange={(dates) => setDateRange(dates)} 
            value={dateRange}
          />
        </Col>
        <Col span={8}>
          <Select
            style={{ width: '100%' }}
            placeholder="Filter by Account"
            onChange={(value) => setAccountFilter(value)}
            value={accountFilter}
          >
            <Option value="">All Accounts</Option>
            {/* Add account options dynamically */}
          </Select>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            placeholder="Filter by Stage"
            onChange={(value) => setStageFilter(value)}
            value={stageFilter}
          >
            <Option value="">All Stages</Option>
            <Option value="Pending Upload">Pending</Option>
            <Option value="Cancled">Processing</Option>
            <Option value="Wire Sent">Completed</Option>
            <Option value="Completed">Completed</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            placeholder="Filter by SLOA on File"
            onChange={(value) => setSloaFilter(value)}
            value={sloaFilter}
          >
            <Option value="">All</Option>
            <Option value="true">Yes</Option>
            <Option value="false">No</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            placeholder="Sort by"
            onChange={(value) => handleSort(value)}
            value={sortField}
          >
            <Option value="date">Date</Option>
            <Option value="amount">Amount</Option>
            <Option value="fund_name">Fund Name</Option>
            <Option value="account_name_from">Account From</Option>
            <Option value="is_verified">SLOA on File</Option>
            <Option value="stage">Stage</Option>
          </Select>
        </Col>
        <Col span={6}>
          <Button onClick={clearFilters} style={{ width: '100%' }}>
            Clear Filters
          </Button>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={24}>
          <Button.Group>
            <Button onClick={() => setQuickFilter('pending')}>Pending Calls</Button>
            <Button onClick={() => setQuickFilter('this_week')}>This Week</Button>
            <Button onClick={() => setQuickFilter('large_amounts')}>Large Amounts</Button>
            <Button onClick={() => setQuickFilter('')}>Clear Quick Filter</Button>
          </Button.Group>
        </Col>
      </Row>
      <div className="capital-call-table">
        <div className="capital-call-header">
          {[
            { label: 'Date', value: 'date' },
            { label: 'Amount', value: 'amount' },
            { label: 'SLOA on File', value: 'is_verified' },
            { label: 'Fund Name', value: 'fund_name' },
            { label: 'Account From', value: 'account_name_from' },
            { label: 'Stage', value: 'stage' },
          ].map(({ label, value }) => (
            <div key={value} onClick={() => handleSort(value)} style={{ cursor: 'pointer' }}>
              {label}
              {sortField === value && (
                <span style={{ marginLeft: '5px' }}>
                  {sortOrder === 'ascend' ? '▲' : '▼'}
                </span>
              )}
            </div>
          ))}
          <div>Actions</div>
        </div>
        {filteredCalls.map(call => (
                <CapitalCallCard 
                    key={call.id} 
                    call={call} 
                    onUpdate={handleUpdate}
                />
            ))}
      </div>
      <Pagination
        current={currentPage}
        total={totalItems}
        pageSize={pageSize}
        onChange={handlePageChange}
        onShowSizeChange={handlePageChange}
        showSizeChanger
        pageSizeOptions={['10', '20', '50', '100']}
        showQuickJumper
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
        style={{ marginTop: '20px', textAlign: 'right' }}
      />
    </div>
  );
};

export default CapitalCallList;