import React from 'react';
import { useDropzone } from 'react-dropzone';
import './CsvFileDropzone.css';

function CsvFileDropzone({ onFilesAdded }) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesAdded,
    accept: {
      'text/csv': ['.csv']
    },
    multiple: true
  });

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      <p>Drag & drop CSV files here, or click to select files</p>
    </div>
  );
}

export default CsvFileDropzone;