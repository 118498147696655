import React from 'react';

const ActivityDetails = ({ event }) => {
  console.log('Event data:', event); // Log the event data to verify fields
  return (
    <>
      <p><strong>Account ID:</strong> {event.account_id}</p>
      <p><strong>Account Name:</strong> {event.account_name}</p>
      <p><strong>Action:</strong> {event.action || 'N/A'}</p>
      <p><strong>Bank:</strong> {event.bank || 'N/A'}</p>
      <p><strong>Credit to Entity:</strong> {event.credit_to_entity || 'N/A'}</p>
      <p><strong>Date:</strong> {new Date(event.date).toLocaleString()}</p>
      <p><strong>Description:</strong> {event.description}</p>
      <p><strong>Fed Reference:</strong> {event.fed_reference || 'N/A'}</p>
      <p><strong>Last Updated:</strong> {new Date(event.last_updated).toLocaleString()}</p>
      <p><strong>Receiving Bank Account Number:</strong> {event.receiving_bank_account_number || 'N/A'}</p>
      <p><strong>Request ID:</strong> {event.request_id || 'N/A'}</p>
      <p><strong>Request Type:</strong> {event.request_type || 'N/A'}</p>
      <p><strong>Send Amount:</strong> {event.send_amount ? `$${event.send_amount.toLocaleString()}` : 'N/A'}</p>
      <p><strong>Source:</strong> {event.source}</p>
      <p><strong>Type:</strong> {event.type}</p>
    </>
  );
};

export default ActivityDetails;