import React from 'react';
import { formatNumber } from '../../utils/formatters/formatters';
import './MoneyMarketSummary.css';

const MoneyMarketSummary = ({ positions }) => {
    const totalValue = positions.reduce((sum, p) => sum + (p.quantity * p.price), 0);

    return (
        <div className="money-market-summary">
            <div className="summary-content">
                <div className="summary-item">
                    <span>Total Value:</span>
                    <strong>{formatNumber(totalValue)}</strong>
                </div>
            </div>
        </div>
    );
};

export default MoneyMarketSummary;