import React from 'react';
import { getEventConfig } from '../../eventConfig';

const ActivityEvent = ({ event, onClick }) => {
  const { emoji, color } = getEventConfig(event.type);
  const displayText = event.description || 'No Description Available';

  return (
    <div 
      className={`calendar-event ${event.type}`}
      style={{ backgroundColor: color }}
      onClick={onClick}
    >
      {emoji} {displayText}
    </div>
  );
};

export default ActivityEvent;